import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  constructor(private router: Router) {}

  checkRole(): boolean {
    const role = localStorage.getItem("role");
    
    if (role === "ADMIN" || role === "REFERENCE") {
      return true;
    } else {
      this.router.navigate(['home']);
      return false;
    }
  }

  checkAdmin(): boolean {
    const role = localStorage.getItem("role");
    
    if (role === "ADMIN") {
      return true;
    } else {
      this.router.navigate(['home']);
      return false;
    }
  }

  checkReference(): boolean {
    const role = localStorage.getItem("role");
    
    if (role === "REFERENCE") {
      return true;
    } else {
      this.router.navigate(['home']);
      return false;
    }
  }
}
