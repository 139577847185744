import { Injectable } from "@angular/core";
import { AdminService } from "./admin-services";
import { UserService } from "./user-service";
import { ReferenceService } from "./reference-service";
import { Delegate } from "../interfaces/delegate";
import { Observable } from "rxjs";
import { CalendarEvent } from "../interfaces/admin.interface";

@Injectable({
    providedIn: 'root'
})
export class RoleService{

    role = ''

    private dataService: Delegate;

constructor(
    private adminService : AdminService,
    private userService: UserService,
    private referenceService: ReferenceService
) {}

setRole(role: string): void {
    if (role === 'ADMIN') {
        this.dataService = this.adminService;
    } else if (role === 'USER') {
        this.dataService = this.userService;
    } else if (role === 'REFERENCE'){
        this.dataService = this.referenceService;
    } else {
        throw new Error('Role not supported');
    }
}


getTodaydata(date : string) : Observable<any> {
    return this.dataService.getTodayData(date)
}
getMapByDateData(date : string): Observable<any>{

    return this.dataService.getMapByDate(date)
}

getRequestsFiltered(type:string, startDate : string, endDate : string, status : string, idUser ?: string, orderBy ?:string) : Observable<any>{
    console.log("dsjsdjdsj", orderBy)
    return this.dataService.getRequests(type, startDate, endDate, status, idUser, orderBy)
}

rejectRequest(idRequest : number){
    return this.dataService.rejectrequest(idRequest)
}

getSpecificUser(id?: string){
    return this.dataService.getSpecificUsers(id)
}

getCalendarData(date : string, id ?: string): Observable<any>{
    return this.dataService.getUsersCalendarData(date, id)
}

getVouchers(calendarDay : string, uuidUser : string): Observable<any>{
    return this.dataService.getVouchers(calendarDay, uuidUser)
}

editCalendarData(calendarId : number, body : any){
    return this.dataService.editCalendar(calendarId, body)
}

postNewCalendar(body : CalendarEvent, idUser?: string){
    return this.dataService.postCalendar(body, idUser)
}

getCalendarDay(date :string, idUser: string){
    return this.dataService.getCalendarDay(date, idUser)
}

getMenu(){
    return this.dataService.getMenu()
}

postRequest(body : any){
    return this.dataService.postRequest(body)
}

getUsers(){
    return this.dataService.getUsers();
}

postPeopleReminder(body){
    return this.dataService.postReminder(body)
}

getWeek(startDate : string, endDate: string) {
    return this.dataService.getWeek(startDate, endDate)
}

}
