import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import * as Highcharts from "highcharts";

@Component({
  selector: "app-column-chart",
  templateUrl: "./high-chart.component.html",
  styleUrls: ["./high-chart.component.scss"],
})
export class HighChartComponent implements OnChanges {
  @Input() items: { key: string; value: number }[] = [];
  @Input() height: number = 300;
  @Input() date: string;
  totalSum: number;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items && !changes.items.firstChange) {
      console.log(this.items)
      this.totalSum = this.getTotalSum();
      this.renderChart();
    }
  }

  private calculateWorkingDays(year: number, month: number): number {
    let totalWorkingDays = 0;
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = new Date(year, month, day);
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        totalWorkingDays++;
      }
    }

    return totalWorkingDays;
  }

  private getTotalSum(): number {
    const [year, month] = this.date.split('-').map(Number);
    const totalWorkingDays = this.calculateWorkingDays(year, month - 1);

    const holidays = this.items
        .filter(item => item.key === 'FESTIVITA')
        .reduce((sum, item) => sum + item.value, 0);

    return totalWorkingDays - holidays;
  }

  private renderChart(): void {
    const colorsMap = {
      ufficio: "rgba(0, 163, 211)",
      remote: "rgb(48, 193, 146)",
      malattia: "rgb(193, 72, 60)",
      trasferta: "rgb(229, 137, 160)",
      ferie: "rgb(225, 167, 35)",
      altro: "rgb(163, 171, 189)",
      listview: "rgba(233, 231, 231, 1)",
      red: "rgba(235, 119, 81, 1)",
      green: "rgba(91, 184, 104, 1)",
      blue: "rgba(65, 115, 176, 1)",
      festivita: "rgb(140, 114, 180)"
    };

    // Convert each value to percentage
    const chartData: Highcharts.PointOptionsObject[] = this.items.map(
      (item) => ({
        name: item.key,
        label: item.key,
        y: (item.value / this.totalSum) * 100, // Calculate percentage and round to 2 decimal places
        color: colorsMap[item.key.toLowerCase()],
      })
    );

    // @ts-ignore
    Highcharts.chart("container", {
      chart: {
        type: "column",
        height: this.height ,
        
      },
      title: {
        text: "Presenze",
      },
      tooltip: {
        valueSuffix: "%",
        valueDecimals: 2,
      },
      series: [
        {
          type: "column",
          name: "",
          color: 'transparent',
          colorByPoint: false,
          allowPointSelect: false,
          data: chartData, // Pass chartData directly here
        },
        
      ],
      yAxis: [{
       labels : {format : "{text} %"},
       title: ""
      }],
      xAxis: {
        type: 'category',
        labels: {
          style: {
            fontSize: '11.5px',
            textAlign: 'center',
            fontWeight: 'bold'
          }
        }
      }
    });
  }
}
