import {Component, OnInit, ViewChild} from "@angular/core";
import {AppService} from "@app/core/services/base.service";
import {CalendarComponent} from "@app/newComponents/calendar/calendar.component";
import {S1Modal, S1ModalSizes} from "@app/s1";
import {FullCalendarComponent} from "@fullcalendar/angular";
import {EventInput, EventSourceInput} from "@fullcalendar/core";
import { ToastrService } from 'ngx-toastr';
import {DateClickArg} from "@fullcalendar/interaction";

@Component({
  selector: "app-festivita",
  templateUrl: "./festivita.component.html",
  styleUrls: ["./festivita.component.scss"],
})
export class FestivitaComponent implements OnInit {
  @ViewChild("calendario") calendar: CalendarComponent;
  @ViewChild("modal") modal: S1Modal;
  @ViewChild("deleteModal") deleteModal: S1Modal;

  date: string = "";
  showError: boolean = false;
  holidays: any[] = [];

  constructor(private appService: AppService, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.getHoldidays()
  }

  getHoldidays(){
    this.appService.getAll("/api/admin/calendar/holiday")
      .subscribe((response: any) => {
        this.holidays = response.results;
        this.createCalendarEvents(response.results) 
      },(error: any)=> {
        this.toastr.error('Errore nel recupero dei dati, riprova più tardi.', 'ERRORE');
      })
  }

  @ViewChild("calendar") calendarComponent: FullCalendarComponent
  events: EventSourceInput = []

  createCalendarEvents(data){
    const events: EventInput[] = []
    console.log(data)
    data.map(data=> {

      const event: EventInput = {
        id: data.id,
        title: "FESTIVITA",
        value:data.id,
        start: data.calendarDay,
        end: data.calendarDay,
        allDay: true,
        backgroundColor: 'rgb(140, 114, 180)',
        borderColor: 'rgb(140, 114, 180)'
  
      };  
      events.push(event)
    })

    this.events = events
    this.calendarComponent.events = this.events
  }
  onConfirm() {
    if (this.date != "") {
      const body = { calendarDay: this.date };
      this.modal.open(S1ModalSizes.LG);
    } else {
      this.showError = true;
    }
  }

  onReset() {
    this.date = "";
    console.log(this.date);
    this.showError = false;
  }

  onDateSelected($event) {
    this.date = $event;
    this.showError = false;
  }

  closeModal() {
    this.modal.close();
  }

  onEventClick(event: DateClickArg){
    console.log("delete holiday")
    this.deleteModal.open(S1ModalSizes.LG);
  }
  deleteHoliday(date: any){
    const holiday = this.holidays.find(h => h.calendarDay == date)
    this.appService.deleteUser(`/api/admin/calendar/delete/holiday/${holiday.id}`)
        .subscribe((response: any) => {
          console.log(response);
          response.outcome.success ? (
              this.deleteModal.close(),
              this.getHoldidays()
          ) : null
        },(error : any) => {
          console.error(error);
            }
        )
  }

  createHoliday() {
    const body = { calendarDay: this.date };
    this.appService.newElement("/api/admin/calendar/holiday", body).subscribe(() => this.getHoldidays());
    this.modal.close();
  }
}
