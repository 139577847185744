import { formatDate } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { format } from "path";

@Component({
  selector: "app-dynamic-list",
  templateUrl: "./dynamic-list.component.html",
  styleUrls: ["./dynamic-list.component.scss"],
})
export class DynamicListComponent implements OnInit {
  @Input() items: { key: string; value: number }[] = [];
  @Input() date: string = ""
  @Input() showTitle: boolean = false
  @Output() status = new EventEmitter<string>();
  @Input() hover: boolean = false;

  title: string =""
  todayDate: string = "";

  get role() {
    return localStorage.getItem('role');
  }

  ngOnInit() {
  /*   this.todayDate = formatDate(new Date(), "dd MMMM yyyy", "it");
    console.log(this.date, this.todayDate)
    this.updateTitle(); */

  }

  capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  ngOnChanges(changes: SimpleChanges): void {
    this.title = "La Situazione"
    changes.date ? console.log("new date", this.date) : null
    if(this.role==="ADMIN" || this.role === "REFERENCE"){
      this.title += this.date === formatDate(new Date(), "dd MMMM yyyy", "it") ||
      this.date === formatDate(new Date(), "yyyy-MM-dd", "it") ? ' di Oggi' : ` di ${this.capitalizeFirstLetter(formatDate(this.date, "EEEE d MMMM yyyy", "it"))}`
    }else{
      const month = this.capitalizeFirstLetter(formatDate(new Date(this.date), "MMMM", "it"));
      this.title += ` di ${month}`;
    }
  }


/*   updateTitle() {
      const formattedDate = formatDate(new Date(this.date), "dd MMMM yyyy", "it");
      console.log(this.todayDate, formattedDate);

      this.title = "La situazione di ";
      if (this.role === "ADMIN") {
        this.title += formattedDate === this.todayDate ? "oggi" : `del ${formattedDate}`;
      } else {
        this.title += formatDate(new Date(), "MMMM", "it");
      }
  } */

  showModal(status: string) {
    if (this.role !== 'USER') {
      this.status.emit(status);
    }
  }
}
