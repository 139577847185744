import { NgIf } from "@angular/common";
import { User } from "@app/core/interfaces/admin.interface";

const user: User = JSON.parse(localStorage.getItem("userLogged"));

const Home = {
  text: "Home",
  link: "/home",
  icon: "fas fa-home",
  section: "HOME"
};

const DipendentiGenerale = {
  text: "Lista",
  link: "/dipendenti",
  section: ""
};

const DipendentiCapoGruppi = {
  text: "Capogruppi",
  link: "/dipendenti/capogruppi",
  section: ""
};

const Dipendenti = {
  text: "Dipendenti",
  link: "/dipendenti",
  icon: "fas fa-users",
  submenu: [DipendentiGenerale],
  //submenu: [DipendentiGenerale, DipendentiCapoGruppi],
  section: "USER"
};

const Membri = {
  text: "Membri",
  link: "/membri",
  icon: "fas fa-users",
  section: "GROUPS"
};

const CalendarioPersonale = {
  text: "Personale",
  link: "/calendario/:id",
  section: ""
};

const CalendarioGenerale = {
  text: "Generale",
  link: "/calendario/all",
  section: ""
};

const Calendar = {
  text: "Calendario",
  link: "/calendario",
  icon: "fas fa-calendar-check",
  submenu: [CalendarioGenerale, CalendarioPersonale],
  section: "CALENDAR"
};

const Vacation = {
  text: "Ferie",
  link: "/ferie",
  icon: "fas fa-umbrella-beach",
  section: "VACATION"
};

const Richieste = {
  text: "Richieste",
  link: "/richieste",
  icon: "fas fa-envelope-open",
  section: "REQUEST"
};

export const menu = [Home, Dipendenti, Membri, Calendar, Vacation, Richieste]
