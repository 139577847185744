<div class="wrapper">
  <div class="block-center mt-4 wd-xl">
    <!-- START card-->
    <div class="card card-flat">
      <div class="card-header text-center bg-secondary">
        <a href="#">
          <img
            class="block-center rounded"
            src="assets/img/logo-big.png"
            alt="Image"
          />
        </a>
      </div>
      <div class="card-body">
        <p class="text-center py-2">Crea nuova password</p>

        <form
          (ngSubmit)="onSubmit()"
          #formRecover="ngForm"
          [formGroup]="changePasswordForm"
          class="form-validate"
          role="form"
          name="formRecover"
          novalidate=""
        >
          <p class="text-center">inserisci il codice inviato a {{ email }}</p>

          <label class="text-muted mb-1">Codice OTP</label>
          <div class="input-group with-focus">
            <input
              class="form-control border-right-0 no-spinners"
              id="OTP"
              type="number"
              name="OTP"
              formControlName="OTP"
            />
            <div class="input-group-append">
              <span
                class="input-group-text text-muted bg-transparent border-left-0"
              >
                <em class="icon-envelope-open"></em>
              </span>
            </div>
          </div>

          <div
            class="text-danger"
            *ngIf="
              (changePasswordForm.get('OTP').hasError('required') ||
                changePasswordForm.get('OTP').hasError('pattern')) &&
              (changePasswordForm.get('OTP').dirty ||
                changePasswordForm.get('OTP').touched)
            "
          >
            L'OTP deve contenere 6 cifre
          </div>
          <!-- PASSWORD -->
          <label class="text-muted mb-1">Password</label>
          <div class="input-group with-focus">
            <input
              class="form-control border-right-0"
              id="password"
              type="password"
              name="password"
              formControlName="password"
            />
            <div class="input-group-append">
              <span
                class="input-group-text text-muted bg-transparent border-left-0"
              >
                <em class="fa fa-lock"></em>
              </span>
            </div>
          </div>
          <div
            class="text-danger"
            *ngIf="
              changePasswordForm.get('password').hasError('required') &&
              (changePasswordForm.get('password').dirty ||
                changePasswordForm.get('password').touched)
            "
          >
            Il campo è obbligatorio
          </div>
          <div
            class="text-danger"
            *ngIf="
              changePasswordForm.get('password').hasError('pattern') &&
              (changePasswordForm.get('password').dirty ||
                changePasswordForm.get('password').touched)
            "
          >
            La password deve contenere almeno una lettera maiuscola, una
            minuscola, un numero e avere una lunghezza maggiore di 8 caratteri
          </div>
          <label class="text-muted mb-1">Ripeti Password</label>
          <div class="input-group with-focus">
            <input
              class="form-control border-right-0"
              type="password"
              name="confirmPassword"
              formControlName="confirmPassword"
            />
            <div class="input-group-append">
              <span
                class="input-group-text text-muted bg-transparent border-left-0"
              >
                <em class="fa fa-lock"></em>
              </span>
            </div>
          </div>
          <div
            class="text-danger"
            *ngIf="
              changePasswordForm.get('confirmPassword').hasError('required') &&
              (changePasswordForm.get('confirmPassword').dirty ||
                changePasswordForm.get('confirmPassword').touched)
            "
          >
            Il campo è obbligatorio
          </div>
          <div
            class="text-danger"
            *ngIf="
              changePasswordForm.get('confirmPassword').hasError('equalTo')
            "
          >
            Le password non corrispondono
          </div>

          <button
            [disabled]="!changePasswordForm.valid"
            class="btn btn-primary btn-block text-white mt-2"
            type="submit"
          >
            MODIFICA PASSWORD
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
