<div class="home-container d-flex justify-content-center mt-5">
  <div class="jumbotron d-flex justify-content-center w-100">
    <div *ngIf="!selectedUser?.id" class="w-25">
      <s1-input-select
        [itemsList]="usersList"
        (onSelect)="showDashboard($event)"
        #select
        [placeholder]="'Seleziona un dipendente'"
      ></s1-input-select>
    </div>

    <section class="container size-home-container page-container p-0 m-0" *ngIf="selectedUser?.id">
      <!-- First Row: Back Button -->
      <div class="row" *ngIf="role == 'ADMIN' || role == 'REFERENCE'">
        <div class="col-12">
          <span class="back-icon" (click)="goBackToGeneral()">
            <i class="fa-solid fa-chevron-left fa-lg cursor"></i> INDIETRO
          </span>
        </div>
      </div>

      <!-- Second Row: Avatar, User Info, and Buttons -->
      <div class="row mt-3 w-100 justify-content-center pb-4 pt-4 border-div">
        <div class="col-3 d-flex justify-content-center align--center">
          <app-avatar [name]="selectedUser.label" [size]="'100'" class="margin-avatar"></app-avatar>
        </div>

        <div class="col-4 d-flex flex-column align-items-center mt-4">
          <div class="mx-5">
            <span class="text-username">
              {{ selectedUser.firstName }} {{ selectedUser.lastName }}
            </span>
          </div>

          <div class="d-flex justify-content-between mt-3">
            <div class="mr-2">
              <app-my-button
                text="CONTATTA"
                (onClick)="openMailBox()"
                icon="fa fa-edit"
                class=""
              >
              </app-my-button>
            </div>
          
            <div>
              <app-my-button
                text="VISUALIZZA"
                (onClick)="redirect()"
                icon="fa fa-eye"
                class=""
              >
              </app-my-button>
            </div>
          </div>
        </div>

        <div class="d-flex flex-column align-items-end col-3">
          <div class="d-flex flex-column">
            <label for="todayDate" class="mb-0 mt-3">Oggi: </label>
            <input
              type="text"
              name="todayDate"
              class="input-group-text m-0 cursor"
              [ngClass]="
                getClassForCalendarStatusType(
                  todayCalendarevent?.calendarStatusType
                )
              "
              [readonly]="true"
              [value]="
                todayCalendarevent
                  ? todayCalendarevent.calendarStatusType
                  : 'INDECISO ✎'
              "
              (click)="
                openModalProva(this.todayDate, todayCalendarevent ?? null)
              "
            />
          </div>
          <div class="mt-2 d-flex flex-column">
            <label for="tomorrowDate" class="mb-0 mt-3"
            >Domani ({{ tomorrowDate | date : "dd/MM" }}):
          </label>
          <input
            type="text"
            name="tomorrowDate"
            class="input-group-text m-0 cursor"
            [ngClass]="
              getClassForCalendarStatusType(
                tomorrowCalendarevent?.calendarStatusType
              )
            "
            [readonly]="true"
            [value]="
              tomorrowCalendarevent
                ? tomorrowCalendarevent.calendarStatusType
                : 'INDECISO ✎'
            "
            (click)="
              openModalProva(this.tomorrowDate, tomorrowCalendarevent ?? null)
            "
          />
          </div>
        </div>
      </div>

      <!-- Third Row: Annual Summary, Calendar, and Monthly Summary -->
      <div class="row mt-4 d-flex justify-content-between pt-4 border-div min-width-container-calendar-list">
        <div class="col-lg-7 col-md-8 col-12 ml-3">
          <!-- Calendar -->
          <div class="container calendar-container">
            <app-my-calendar
              (startMonthSelected)="handleCalendarMonth($event)"
              (buttonClick)="onCalendarButtonClick($event)"
              [events]="events"
              [height]="400"
              [selectDate]="false"
              [showModal]="true"
              [user]="selectedUser"
              (onModalClose)="resetDataList()"
            ></app-my-calendar>
          </div>
        </div>

        <div class="col-lg-4 col-md-4 col-12 mt-4">
          <!-- Monthly Summary -->
          <div
            class="w-100 px-1"
            [ngClass]="{ ' border-bottom border-danger': !isDateValid }"
          >
            <label htmlFor="monthPicker">Seleziona date:</label>

            <button
              (click)="showMonthPicker()"
              *ngIf="!isMonthPickerVisible"
              class="btn w-100 btn-outline-secondary"
            >
              <i class="fa-regular fa-calendar"></i>
              {{
                startDate && endDate
                  ? startDate + " - " + endDate
                  : "CALENDARIO"
              }}
            </button>

            <month-picker
              (monthRangeSelected)="onMonthSelected($event)"
              *ngIf="isMonthPickerVisible"
              (displayEvent)="changeMonthPickerVisibility($event)"
              (year)="onYearSelected($event)"
              [selectionMode]="'single'"
            ></month-picker>
          </div>

          <div class="mt-0">
            <app-dynamic-list [items]="dataList" [hover]="true"></app-dynamic-list>
          </div>
        </div>
      </div>
    </section>
  </div>
  <s1-modal #modal [deleteUser]="true">
    <app-modal-user
      [user]="user"
      [modalType]="modalType"
    >
    </app-modal-user>
  </s1-modal>
</div>

