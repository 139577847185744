import { Component, OnInit } from "@angular/core";
import { SettingsService } from "../../../core/settings/settings.service";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import * as CryptoJS from 'crypto-js';

import { LoginData } from "../../../../app/core/classes/loginData.module";

import { LoggingService } from "../../../../app/core/services/log.service";
import { AuthService } from "../../../../app/core/services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import swal from "sweetalert2";
import { TranslatorService } from "@app/core/translator/translator.service";
import { environment, userLogged } from "src/environments/environment";
import { AppService } from "@app/core/services/base.service";
import { User } from "@app/core/interfaces/admin.interface";
import { MenuService } from '../../../core/menu/menu.service';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  rememberMe: boolean = false;
  hashedPassword: string;

  // params from path
  paramId;

  // string for errorMessage service
  msgTranslation;
  errorTranslation;
  errorMessage;

  routeToFollow: string = "/home";

  constructor(
    public settings: SettingsService,
    private formBuilder: FormBuilder,
    private appService: AppService,
    private logger: LoggingService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private translate: TranslateService,
    private translator: TranslatorService
  ) {
    this.translate.setDefaultLang(this.translator.getLanguageInUse());

    // get text message
    //this.errorTranslation =  this.translate.get('error');
    this.translate.get("error").subscribe((text) => {
      this.errorTranslation = text;
    });

    //this.msgTranslation =  this.translate.get('msg');
    this.translate.get("msg").subscribe((text) => {
      this.msgTranslation = text;
    });

    this.paramId = this.route.params.subscribe((params) => {
      const id = params["id"]; // (+) converts string 'id' to a number
      if (id != null && this.msgTranslation) {
        this.errorMessage = this.msgTranslation.logout_ok;

        setTimeout(() => {
          this.errorMessage = null;
        }, 3000);
      }
    });

    this.loginForm = this.formBuilder.group({
      username: [null, Validators.required],
      password: [null, Validators.required],
    });
  }

  onCheckChange = (checked: boolean) => {
    this.rememberMe = checked;
  }

  onSubmit(): void {
    const lData = new LoginData(
      this.loginForm.value.username,
      this.loginForm.value.password
    );
    swal.fire({
      title: this.msgTranslation.loading,
      confirmButtonColor: '#10509C',
      didOpen: () => {
        swal.showLoading();
      },
    });
    this.authService.login(lData).subscribe(
      (response) => {
        if (response.outcome.success === true) {
          this.findUser();
          if (this.rememberMe) {
            this.rememberUser();
          } else {
            this.removeUser();
          }
          swal.close();
        } else {
          swal.fire(
            this.msgTranslation.error,
            this.settings.manageErrorMsg(response.outcome),
            "error"
          );
        }
      },
      (error) => {
        this.logger.log("Error", error, 200);
        this.errorMessage =
          this.errorTranslation.credentials_error +
          " " +
          this.errorTranslation.try_again;
        swal.fire(this.msgTranslation.error, this.errorMessage, "error");
      }
    );
  }

  rememberUser(): void {
    localStorage.setItem("username", this.loginForm.value.username);
    this.hashedPassword = this.hashPassword(this.loginForm.value.password);
    localStorage.setItem("password", this.hashedPassword);
  }

  removeUser(): void {
    localStorage.removeItem("username");
    localStorage.removeItem("password");
  }

  hashPassword(password: string): string {
    return CryptoJS.AES.encrypt(password, environment.secretKey).toString();
  }

  decryptPassword(hashedPassword: string): string {
    const bytes = CryptoJS.AES.decrypt(hashedPassword, environment.secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  ngOnInit() {
    if (localStorage.getItem('username')) {
      this.loginForm.patchValue({
        username: localStorage.getItem('username'),
        password: this.decryptPassword(localStorage.getItem('password'))
      });
      this.rememberMe = true;
    }
  }

  findUser() {
    return new Promise((resolve, reject) => {
      this.appService.getElement("/getuser").subscribe(
        (res: User) => {
          const user = {
            id: res.id,
            firstName: res.firstName,
            lastName: res.lastName,
            email: res.email,
            roles: res.roles,
            status: res.status,
            username: res.username,
          };

          localStorage.setItem("userLogged", JSON.stringify(user));
          resolve(user);

          if (user.roles.length > 1) {
            this.routeToFollow = "/selectrole";
          } else {
            localStorage.setItem('role', user.roles[0].toString())

          }
        },
        (error) => {
          reject(error);
          console.error("Error fetching user details", error);
        }
      );
    }).then(() => {
      this.router.navigate([this.routeToFollow]);
    });
  }
}
