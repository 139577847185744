import { toUpper } from 'lodash';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { Calendar, EventClickArg, EventSourceInput } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CreateCalendareventModalComponent } from "../modals/create-calendarevent-modal/create-calendarevent-modal.component";
import { CalendarEvent } from "@app/core/interfaces/admin.interface";
import { formatDate } from "@angular/common";
import { AppService } from '../../core/services/base.service';
import { RoleService } from '../../core/services/role-service';
import { info } from "node:console";

@Component({
  selector: "app-my-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.scss"],
})
export class CalendarComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild("calendar", { static: true }) calendarElement: ElementRef;
  @Input() events: EventSourceInput = [];
  @Input() height: number;
  @Output() onModalClose = new EventEmitter<any>()
  @Input() showSelectedCell: boolean = false;
  @Output() dateSelected = new EventEmitter<string>();
  @Output() eventClick = new EventEmitter<DateClickArg>();
  @Output() startMonthSelected = new EventEmitter<string>();
  @Output() endMonthSelected = new EventEmitter<string>();
  @Input() isAdmin: boolean = false; // Input per determinare se l'utente è ADMIN
  @Input() selectDate: boolean = false; // Impostato su false di default
  @Input() showModal: boolean = false; // Impostato su false di default
  @Input() user: any = null
  @Input() selectedDateEventClick: string = "";
  @Input() startingMonth: string = "";
  @Input() isVacationSelected: boolean = false;
  @Input() vacation: boolean = false
  @Output() eventCreated = new EventEmitter<void>();
  @Output() buttonClick = new EventEmitter<any>();
  modalRef: BsModalRef;
  calendar: Calendar;

  constructor(
      private modalService: BsModalService,
      private appService: AppService,
      private roleService: RoleService
  ) {}
  userLogged: any = null
  dateExpiredVacation: any = null
  isExpiredVacation: boolean = false

  ngOnInit(): void {
    this.initializeCalendar();
    this.isAdmin = this.role === "ADMIN" || this.role === "REFERENCE";
    this.userLogged = JSON.parse( localStorage.getItem("userLogged"))
  }

  get role() {
    return localStorage.getItem("role");
  }

  ngOnDestroy(): void {
    if (this.calendar) {
      this.calendar.destroy();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes.events);
    if (changes.events && !changes.events.firstChange && this.calendar) {
      this.updateEvents();
    }
  }

  initializeCalendar(date?: string): void {
    let prevClickedCell: HTMLElement | null = null;
    console.log(date)
    let initialDate: Date = date ? new Date(date) : new Date();
    console.log(initialDate);
    if(localStorage.getItem('dateVacationSelected')) {
      this.isExpiredVacation = true
      this.dateExpiredVacation = localStorage.getItem('dateVacationSelected');
      localStorage.removeItem('dateVacationSelected');
      initialDate = this.isVacationSelected && this.startingMonth 
      ? new Date(this.startingMonth) 
      : this.isExpiredVacation 
        ? this.dateExpiredVacation
        : new Date();
    }


    console.log('INITIAL DATE:', initialDate);
    this.calendar = new Calendar(this.calendarElement.nativeElement, {
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: "dayGridMonth",
      initialDate: initialDate,
      editable: false,
      contentHeight: this.height,
      titleFormat: { year: 'numeric', month: 'long' },
      buttonText: {
        today: 'Oggi',
      },
      dateClick: ((info) => {
        console.log('Vacation:', this.vacation);
        console.log('Show Selected Cell:', this.showSelectedCell);
        console.log('Condition:', this.showSelectedCell && !this.vacation);
        if (this.showSelectedCell && !this.vacation) {
          if (prevClickedCell) {
            prevClickedCell.style.backgroundColor = '';
          }
          info.dayEl.style.backgroundColor = 'rgba(233, 231, 231, 1)';
          prevClickedCell = info.dayEl;
        }
        this.onDateClick(info);
      }),
      eventClick: (info) => {
        if (!this.vacation) {
          this.onEventClick(info);
        }
      },
      dayMaxEvents: 1,
      firstDay: 1,
      locale: "it",
      timeZone: "Europe/Rome",
      events: this.events,
      eventBackgroundColor: "rgb(225, 167, 35)",
      eventBorderColor: "rgb(225, 167, 35)",
      eventTextColor: "black",
      datesSet: () => {
        const buttons = this.calendarElement.nativeElement.querySelectorAll('.fc-button');
        buttons.forEach((button: HTMLElement) => {
          button.style.backgroundColor = '#10509C';
          button.style.border = 'none';
        });
        const titleElement = this.calendarElement.nativeElement.querySelector('.fc-toolbar-title');
        if (titleElement) {
          let text = titleElement.textContent || '';
          
          const words = text.split(' ');
      
          if (words.length >= 2 && words[1].length > 4) {
            words[1] = words[1].substring(4);
            text = words.slice(1).join(' ').trim(); 
          }
  
          titleElement.textContent = text;
      
          const capitalizedText = titleElement.textContent
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');  
  
          titleElement.textContent = capitalizedText;
          this.buttonClick.emit({ capitalizedText });
        }
      },
    });
  
    this.calendar.render();
    setTimeout(() => {
      this.calendar.updateSize();
    }, 0);
  }

  updateEvents(): void {
    if (this.calendar) {
      console.warn(this.events);
      this.calendar.removeAllEvents();
      this.calendar.addEventSource(this.events);
      this.calendar.render();
    }
  }

  onDateClick(info: DateClickArg): void {
    if(!this.vacation) {
      console.log("DATE CLICK");
      const currentDate = formatDate(new Date(), "yyyy-MM-dd", "it");
      this.selectedDateEventClick = formatDate(new Date(info.date), "yyyy-MM-dd", "it");
      this.emitDateSelected(this.selectedDateEventClick)
  
      if ((this.isAdmin) || (!this.isAdmin && this.selectedDateEventClick >= currentDate)) {
        console.log("OPEN MODAL");
        const formattedDate: string = info.dateStr;
        this.emitDateSelected(formattedDate);
        this.openCreateEventModal(info);
        if (this.showSelectedCell) {
          const dayEl: HTMLElement = info.dayEl;
          dayEl.style.backgroundColor = "rgba(233, 231, 231, 1)";
        }
      }
    }
  }

  onEventClick(info: EventClickArg): void {
    console.log("DATE CLICK");
    // @ts-ignore
    this.eventClick.emit(info);
    const currentDate = formatDate(new Date(), "yyyy-MM-dd", "it");
    this.selectedDateEventClick = formatDate(new Date(info.event.start), "yyyy-MM-dd", "it");
    this.emitDateSelected(this.selectedDateEventClick)
    console.log("SELECTED DATE", this.selectedDateEventClick);
    if ((this.isAdmin) || (!this.isAdmin && this.selectedDateEventClick >= currentDate)) {
      console.log("OPEN MODAL");
      const formattedDate: string = this.selectedDateEventClick;
      this.emitDateSelected(formattedDate);
      this.openCreateEventModal(info);
      if (this.showSelectedCell) {
        const dayEl: HTMLElement = info.el;
        dayEl.style.backgroundColor = "rgba(233, 231, 231, 1)";
      }
    }
  }

  emitDateSelected(formattedDate: string): void {
    this.dateSelected.emit(formattedDate);
  }

  onDatesSet(): void {
    const currentDate = this.calendar.getDate();
    const formattedStartMonth = this.formatDate(currentDate);
    const formattedEndMonth = this.formatEndDate(currentDate);

    this.startMonthSelected.emit(formattedStartMonth);
    this.endMonthSelected.emit(formattedEndMonth);
  }

  openCreateEventModal(info: any): void {
    const selectedDate: string = info.dateStr || formatDate(info.event.start, "yyyy-MM-dd", "it");
    console.log("SELECTED DATE", selectedDate);
    console.log("MODAL APERTA COME ", this.role)

    !this.isAdmin ? this.user = JSON.parse(localStorage.getItem("userLogged")) : null
    this.roleService.getCalendarDay(selectedDate, this.isAdmin ? this.user.id : null)
        .subscribe((response: any) => {
          console.log(response);
          const calendarEvent = response.results.length > 0 ? response.results[0] : null;

          if (!calendarEvent || (calendarEvent.calendarStatusType !== "FERIE" && calendarEvent.calendarStatusType !== "FESTIVITA")) {
            this.modalRef = this.modalService.show(CreateCalendareventModalComponent, {
              initialState: {
                date: selectedDate,
                calendarEvent: calendarEvent,
                userId: this.user.id
              },
            });

            this.modalRef.content.eventCreated.subscribe(() => {
              this.onEventCreated();
            });

            this.modalRef.content.onClose.subscribe(() => {
              this.updateEvents();
              this.initializeCalendar(this.selectedDateEventClick);
              this.onModalClose.emit("")
              this.selectedDateEventClick = "";
            });
          }
        });
  }

  onEventCreated(): void {
    this.eventClick.emit();
  }
  
  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    return `${year}-${month}`;
  }

  formatEndDate(date: Date): string {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 2)).slice(-2);
    return `${year}-${month}`;
  }

  get userId(): string {
    const user = JSON.parse(localStorage.getItem("userLogged"));
    return user.id;
  }

  selectedDate(info: any, cell: any) {
    if (this.selectDate === true) {
      const year: number = info.date.getFullYear();
      const month: string = ("0" + (info.date.getMonth() + 1)).slice(-2);
      const day: string = ("0" + info.date.getDate()).slice(-2);
      const formattedDate: string = `${year}-${month}-${day}`;

      if (this.showSelectedCell) {
        if (cell) {
          cell.style.backgroundColor = "";
        }
        info.dayEl.style.backgroundColor = "rgba(233, 231, 231, 1)";
        cell = info.dayEl;
      }

      this.dateSelected.emit(formattedDate);
    }
  }
}
