import { UserGroup, Permission, Configuration } from "./core.interface";
export enum LogLevel {
  ALL = 100,
  DEBUG = 200,
  INFO = 300,
  PROD = 400,
}

export enum SelectionType {
  SINGLE = "SINGLE",
  MULTIPLE = "MULTIPLE",
}

export enum State {
  ALL = 2,
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum BlocksState {
  ALL = 2,
  WITHOUT_BLOCKS = 0,
  WITH_BLOCKS = 1,
}

export interface LoggedUserInfo {
  token: string;
  name: string;
  surname: string;
  namesurname?: string;
  enabled: boolean;
  role: string;
  admin: boolean;
  groups: UserGroup[];
  permissions: Permission[];
  configuration: Configuration;
}

export interface RequestBody {
  paging: boolean;
  page: number;
  rows: number;
  summary?: boolean;
  orderBy?: string;
  direction?: string;
  filters: EntityEnum;
}

export interface EntityEnum {}

export enum PossibleActionsType {
  EDIT = "EDIT",
  DELETE = "DELETE",
  DUPLICIATE = "DUPLICIATE",
  TOGGLE_STATE = "TOGGLE_STATE",
}

export interface PossibleActions {
  edit?: boolean;
  delete?: boolean;
  duplicate?: boolean;
  disassociate?: boolean;
}

export interface CellConfiguration {
  title?: string;
  name?: string;
  key?: string;
  onChip?: boolean;
}

export interface SweetAlertMsg {
  loadingText?: string;
  creationText?: string;
  updatingText?: string;
  errorText?: string;
  firstError?: string;
  secondError?: string;
  warning?: string;
}

export interface ElementState {
  value: number;
  name: string;
}

export enum RolesEnum {
  "ADMIN",
  "REFERENCE",
  "USER",
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  roles: RolesEnum[];
  status: string;
  username: string;
  password: string;
  totFerie: number;
  totPermessi: number;
}

export interface Reference {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  roles: [],
  status: "",
  username: "",
  password: "",
  totFerie: 0,
  totPermessi: 0
};

export interface CalendarEvent {
  ferieAndPermessi: any;
  calendarStatusType: string;
  user?: User;
  calendarDay: string;
  message?: string;
  ore?: number;
  id?: number;
}

export interface CalendarEvents {
  [key: string]: CalendarEvent[];
}

export enum StatusEnum {
  "UFFICIO",
  "REMOTE",
  "FESTIVITA",
  "FERIE",
  "MALATTIA",
  "PERMESSO",
  "TRASFERTA",
}
