<div class="modal-header">
  <h4 class="modal-title">{{ date | date : "dd/MM/yyyy" }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form (ngSubmit)="onSubmit()">
  <div class="d-flex flex-column p-3" style="row-gap: 1rem">
    <!-- STATUS -->
    <div
      class="d-flex flex-row justify-content-between align-items-center w-100"
      style="gap: 1rem"
    >
      <div
        class="text-uppercase py-5 rounded shadow cursor bg-ufficio-color w-100 status-btn"
        [ngClass]="{
          active: 'UFFICIO' === selectedStatus
        }"
        (click)="toggleSelectedStatus('UFFICIO')"
      >
        <h4 class="m-0 text-white text-center">UFFICIO</h4>
      </div>
      <div
        class="text-uppercase py-5 rounded shadow cursor bg-remote-color w-100 status-btn"
        [ngClass]="{
          active: 'REMOTE' === selectedStatus
        }"
        (click)="toggleSelectedStatus('REMOTE')"
      >
        <h4 class="m-0 text-white text-center">REMOTE</h4>
      </div>
    </div>
    <div
      class="d-flex flex-row justify-content-between align-items-center w-100"
      style="gap: 1rem"
    >
      <div
        class="text-uppercase py-5 rounded shadow cursor bg-trasferta-color w-100 status-btn"
        [ngClass]="{
          active: 'TRASFERTA' === selectedStatus
        }"
        (click)="toggleSelectedStatus('TRASFERTA')"
      >
        <h4 class="m-0 text-white text-center">TRASFERTA</h4>
      </div>
      <div
        class="text-uppercase py-5 rounded shadow cursor bg-malattia-color w-100 status-btn"
        [ngClass]="{
          active: 'MALATTIA' === selectedStatus
        }"
        (click)="toggleSelectedStatus('MALATTIA')"
      >
        <h4 class="m-0 text-white text-center">MALATTIA</h4>
      </div>
    
    </div>
    <div
        *ngIf="role === 'ADMIN'"
        class="text-uppercase py-5 rounded shadow cursor bg-ferie-color w-100 status-btn"
        [ngClass]="{
          active: 'FERIE' === selectedStatus
        }"
        (click)="toggleSelectedStatus('FERIE')"
      >
      <h4 class="m-0 text-white text-center">FERIE</h4>
    </div>

    <!-- NOTE -->
    <div class="py-3">
      <p class="text-md-start">NOTE:</p>
      <textarea class="p-3 w-100" [(ngModel)]="message" name="note"></textarea>
    </div>

    <!-- ORE DI PERMESSO -->
    <div
      class="d-flex flex-row align-items-center justify-content-between"
      *ngIf="selectedStatus === 'UFFICIO' || selectedStatus === 'REMOTE'"
    >
      <p class="text-md-start">ORE DI PERMESSO:</p>
      <input
        type="number"
        class="input-number"
        [(ngModel)]="ore"
        name="ore"
      />
    </div>

    <!-- BUTTON -->
    <div class="d-flex justify-content-end">
      <app-my-button
        type="submit"
        text="CONFERMA"
        icon="fa fa-save"
        bgColor="green-color"
        [disabled]="!selectedStatus"
      >
      </app-my-button>
    </div>
  </div>
</form>
