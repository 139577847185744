import {Component,OnInit,Input,ViewChild,Output,ElementRef,EventEmitter,} from "@angular/core";
import { Router } from "@angular/router";
import { RequestService } from "@app/core/services/request.service";
import { RoleService } from "@app/core/services/role-service";
import { S1Modal } from "@app/s1";

@Component({
  selector: "app-admin-request",
  templateUrl: "./admin-request.component.html",
  styleUrls: ["./admin-request.component.scss"],
})
export class AdminRequestComponent implements OnInit {
  @ViewChild("modal") modal: S1Modal;
  @ViewChild("approveReject") approveReject: S1Modal;
  @Input() isApprove: boolean;
  
  @Input() request: any = {};
  requestStatus: string;
  constructor(private requestService: RequestService, private router: Router, private roleService : RoleService) {}

  @Output() modalEvent = new EventEmitter();
  @Output() showModal = new EventEmitter<string>();

  months: string[] = [
    "Gennaio","Febbraio","Marzo","Aprile","Maggio","Giugno","Luglio","Agosto","Settembre","Ottobre","Novembre","Dicembre",
  ];

  adminOperation: boolean = false;
  modalTitle: string = "RICHIESTA DI ";
  modalMessage: string = "";
  monthString: string = "";
  brandPrimary: string;
  brandSecondary: string;
  brandTertiary: string;

  ngOnInit(): void {
    /* this.request.requestStatus == "APPROVED"
      ? (this.requestStatus = "APPROVATA")
      : null;
    this.request.requestStatus == "PENDING"
      ? (this.requestStatus = "IN ATTESA")
      : null;
    this.request.requestStatus == "REJECTED"
      ? (this.requestStatus = "RIFIUTATA")
      : null; */


      this.roleService.setRole(this.role)
  }

  get role(): string{
    return localStorage.getItem("role")
  }
  openModal(adminOperation: boolean, request: any, isApprove?: boolean) {
    this.adminOperation = adminOperation;
    this.isApprove = isApprove;

    request.requestType === "FERIE"
      ? this.approveReject.open()
      : this.modal.open();

    /* request.requestType === "FERIE"
      ? ((this.modalTitle += "FERIE"),
        (this.modalMessage = `Sei sicuro di voler ${
          adminOperation ? "APPROVARE" : "RIFIUTARE"
        } la richiesta di ferie di ${request.user.firstName} 
        ${request.user.lastName} dal ${request.startDate} al ${
          request.endDate
        }?`))
      : ((this.monthString = request.startDate.substring(5, 7)),
        (this.modalTitle += "CHIUSURA MENSILE"),
        (this.modalMessage = `${
          adminOperation ? "APPROVANDO" : "RIFIUTANDO"
        } questa richiesta rendi i giorni di ${request.user.firstName} ${
          request.user.lastName
        }
      per il mese di ${
        this.months[parseInt(this.monthString)]
      } immodificabili`));

    this.modal.open(); */
  }

  approveRequest(id: number) {
    this.requestService.approveRequest(id)
        .subscribe({
          next: (data) => {
            console.log(data);
            this.modal.close();
            this.modalEvent.emit('approved');
          },
          error: (err) => {
            console.error('Error approving request:', err);
          }
        });
  }

  rejectRequest(id: number) {
    this.roleService.rejectRequest(id)
        .subscribe({
          next: (data) => {
            console.log(data);
            this.modal.close();
            this.modalEvent.emit('rejected');
          },
          error: (err) => {
            console.error('Error rejecting request:', err);
          }
        });
  }

  linkTo(event): void {
    if (event.requestType === "FERIE") {
      this.router.navigate([`/ferie`]);
      localStorage.setItem("userVacation", event.user.id);
      localStorage.setItem("vacationSelected", event.id);
      localStorage.setItem("dateVacationSelected", event.startDate);
    } else {
      this.router.navigate([`/calendario/${event.user.id}`]);
    }
  }

  closeModal() {
    this.modal.close();
  }
}
