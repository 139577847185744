import { HttpClient } from '@angular/common/http';
import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppService } from '@app/core/services/base.service';
import { S1Modal, S1ModalSizes, S1Table } from '@app/s1';
import { CalendarEvent, User, CalendarEvents } from '@app/core/interfaces/admin.interface';
import { size } from 'lodash';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { DynamicListComponent } from '@app/newComponents/dynamic-list/dynamic-list.component';


@Component({
  selector: 'app-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.scss'],
})
export class UserHomeComponent implements OnInit, OnDestroy {

  @ViewChild('modal') modal: S1Modal;
  @ViewChild("dynamicList") dynamicList: DynamicListComponent
  dynamicListItems: { key: string; value: number }[] = [];
  listFiltered: User[] = []; // Array to store filtered users
  otherList: CalendarEvent[] = []; // Array to store CalendarEventEntry objects for 'FESTIVITA' and users with no events

  date = new Date();
  monthYearSelector: "MONTH" | "YEAR" = "MONTH"
  currYear: number = this.date.getFullYear();
  currMonthYear: string = formatDate(new Date(), "MM/yyyy", "it-IT");
  currMonth: number = new Date().getMonth() + 1; // Adding 1 to month as getMonth() returns 0-11

  users: User[] = []; // Define type as User[]
  calendarData: CalendarEvents = {}; // Define type as CalendarEvents
  statusKey: string; // Renamed to avoid confusion with EventEmitter
  navigationPath : string

  constructor(private appService: AppService, private http: HttpClient, private router : Router,private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.loadData()
  }

  ngOnDestroy(): void {
    const calendar = document.getElementById('calendar');
    if (calendar) {
      calendar.remove();
    }
  }

  onDateSelected($event: any) {
    //this.selectedDate = $event;
    //this.loadData();
  }

  onShowChange($event: any) {
    this.statusKey = $event;
    //this.setModalList(this.calendarData); // Call setModalList to filter users
    this.modal.open(S1ModalSizes.LG);
  }

  loadData() {
    const formattedMonth = String(this.currMonth).padStart(2, '0'); 
    this.appService.getAllMap(`/api/user/calendar/map?startDate=${this.currYear}-${formattedMonth}`).subscribe(
      (data) => {
        this.calendarData = data;
        console.log(data);
        this.updateDynamicList(data); // Ensure this is called
      },
      (error) => {
        console.error(error);
      }
    );
  }
  

  updateDynamicList(data: CalendarEvents) {
    this.dynamicListItems = []; // Reset the list
    Object.keys(data).forEach((key) => {
      const item = {
        key: key,
        value: data[key].length,
      };
      if (key !== 'FESTIVITA' && key !== 'PERMESSO') {
        this.dynamicListItems.push(item);
      }
    });
    console.log("DYNAMIC LIST ITEMS", this.dynamicListItems); // Check if this logs correctly
  }
  

  onNavigation($event : string) : void {
    this.navigationPath = $event
    this.modal.close()
    this.router.navigate([this.navigationPath])
  }

  // DATE FUNCTIONS 
  prevYear(): void {
    this.currYear = new Date(
      this.date.setFullYear(this.date.getFullYear() - 1)
    ).getFullYear();
  }

  nextYear(): void {
    this.currYear = new Date(
      this.date.setFullYear(this.date.getFullYear() + 1)
    ).getFullYear();
  }

  prevMonth(): void {
    this.currMonthYear = formatDate(
      new Date().setMonth(this.currMonth - 1),
      "MM/yyyy",
      "it-IT"
    );
    this.currMonth -= 1;
    if (this.currMonth < 1) {
      this.currMonth = 12;
      this.prevYear();
    }
  }

  nextMonth(): void {
    this.currMonthYear = formatDate(
      new Date().setMonth(this.currMonth + 1),
      "MM/yyyy",
      "it-IT"
    );
    this.currMonth += 1;
    if (this.currMonth > 12) {
      this.currMonth = 1;
      this.nextYear();
    }
  }

  changeDateSelector(){
    this.monthYearSelector === "YEAR" ? this.monthYearSelector = "MONTH" : this.monthYearSelector = "YEAR"
  }
}
