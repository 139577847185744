<ng-container *ngTemplateOutlet="selectedShow"></ng-container>

<!-- <ng-template #menu>
  <div class="container d-flex row justify-content-center">
    <div class="d-flex flex-column align-items-center m-0">
      <span class="fa-solid fa-user user-icon"></span>
      <app-my-button text="Profilo" (onClick)="setProfilo()"> </app-my-button>
    </div>
     <div class="d-block col">
      <span class="fa-solid fa-house-chimney holiday-icon"></span>
      <span
        class="border border-black p-2 rounded holiday"
        (click)="navigateToHoliday()"
        ><i class="icon-action-redo"></i> Festivita</span
      >
    </div> 
  </div>
</ng-template>
 -->
<ng-template #profilo>
  <div class="container">
    <!-- Content for show modal -->
    <form *ngIf="form" [formGroup]="form">
      <div class="row mb-4">
        <!-- Avatar -->
        <div class="col-4 align-items-center">
          <app-avatar [name]="user?.firstName" size="110"></app-avatar>
        </div>

        <!-- informazioni base -->
        <div class="col-8 align-center">
          <s1-input-text
            [control]="form.controls.username"
            [label]="'Username'"
            [showSearch]="false"
            [readonly]="true"
            class="mt-2 bg-white"
          ></s1-input-text>
          <s1-input-text
            [control]="form.controls.firstName"
            [label]="'Nome:'"
            [placeholder]="'ex. Mario'"
            [showSearch]="false"
            [readonly]="true"
            class=""
          ></s1-input-text>
          <s1-input-text
            [control]="form.controls.lastName"
            [label]="'Cognome:'"
            [placeholder]="'ex. Rossi'"
            [showSearch]="false"
            [readonly]="true"
            class=""
          ></s1-input-text>
        </div>
      </div>
      <!-- email e stato -->
      <div class="row mb-3 align-items-center">
        <s1-input-text
          [control]="form.controls.email"
          [label]="'Email:'"
          [placeholder]="'ex. mariorossi@gmail.com'"
          [showSearch]="false"
          [readonly]="true"
          class="col-12"
        ></s1-input-text>
      </div>
      <!-- Ruoli -->
      <div class="d-flex pl-0 align-items-center justify-content-between">
        <s1-input-text
          [control]="form.controls.roles"
          [label]="'Roles:'"
          [showSearch]="false"
          [readonly]="true"
          class="col-6 p-0"
        ></s1-input-text>
        <s1-input-text
          [control]="form.controls.status"
          [label]="'Status:'"
          [placeholder]="''"
          [showSearch]="false"
          [readonly]="true"
          class="col-6 p-0 pl-2"
        ></s1-input-text>
      </div>
    </form>
  </div>
</ng-template>
