// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { User } from "@app/core/interfaces/admin.interface";

const config = require('../assets/config/config-test.json');

export const userLogged  = {
  firstName: "",
  lastName: "",
  email: "",
  roles: [],
  status: "",
  username: "",
}


export const userSelected = {
  firstName: "",
  lastName: "",
  email: "",
  roles: [],
  status: "",
  username: "",
}



export const environment = {
  production: config.production,
  version: "1.0.0",
  restBaseUrl: config.apiBaseUrl,
  rows: 10,
  userLogged : userLogged,
  userSelected : userSelected,
  secretKey: config.secretKey
};




/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
