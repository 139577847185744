<nav class="navbar topnavbar navbar-style" role="navigation">
  <ul class="navbar-nav mr-auto flex-row">
    <li class="nav-item item">
      <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
      <a
        class="nav-link d-none d-md-block d-lg-block d-xl-block"
        trigger-resize=""
        (click)="toggleCollapsedSideabar()"
        *ngIf="!isCollapsedText()"
      >
        <em class="fas fa-bars"></em>
      </a>
      <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
      <a
        class="nav-link sidebar-toggle d-md-none"
        (click)="
          settings.toggleLayoutSetting('asideToggled'); $event.stopPropagation()
        "
      >
        <em class="fas fa-bars fa-lg"></em>
      </a>
    </li>
  </ul>

  <!-- PARTE CENTRALE -->
  <div class="mr-auto w-25" *ngIf="role !== 'USER'">
    <s1-input-select
      #input
      [itemsList]="usersFound"
      class="w-100"
      style="color: white;"
      placeholder="Cerca un dipendente"
      (search)="onNameValueChange($event)"
      (onSelect)="selectedUser($event)"
    >
    </s1-input-select>
  </div>
  <!-- PARTE DESTRA -->
  <ul class="navbar-nav flex-row mr-4 align-items-center" >
    <li class="nav-item item" *ngIf="roleButtonVisible">
      <a class="nav-link" (click)="changeRole()">
        <button class="btn change-role-btn">CAMBIA RUOLO</button>
      </a>
    </li>
    <li class="nav-item item" *ngIf="role == 'ADMIN' || role == 'REFERENCE'">
      <a
        class="nav-link"
        (click)="toggleOffsidebar(); $event.stopPropagation()"
      >
        <em class="icon-bell fa-lg"></em>
      </a>
    </li>

    <li class="nav-item item">
      <a class="nav-link" (click)="logout()">
        <em class="icon-logout fa-lg"></em>
      </a>
    </li>
  </ul>

  <app-navsearch
    [visible]="getNavSearchVisible()"
    (onclose)="setNavSearchVisible(false)"
  ></app-navsearch>
</nav>
