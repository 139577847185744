import {
  Component,
  OnInit,
  ViewChild,
  Injector,
  DoCheck,
  Input,
} from "@angular/core";
import { Router, RouterConfigOptions } from "@angular/router";
const screenfull = require("screenfull");

import { UserblockService } from "../sidebar/userblock/userblock.service";
import { SettingsService } from "../../core/settings/settings.service";
import { MenuService } from "../../core/menu/menu.service";
import { TranslatorService } from "../../core/translator/translator.service";
import { AppService } from "@app/core/services/base.service";
import { IS1InputSelectItem, S1InputSelect } from "@app/s1";
import { User } from "@app/core/interfaces/admin.interface";
import { map } from "rxjs/operators";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  navCollapsed = true;
  menuItems = [];
  router: Router;
  selectedLanguage = "";
  usersFound: IS1InputSelectItem[] = [];
  nameValue: string = "";

  isNavSearchVisible: boolean;
  @ViewChild("fsbutton", { static: true }) fsbutton;
  @ViewChild("input") input: S1InputSelect;

  constructor(
    public menu: MenuService,
    public userblockService: UserblockService,
    public settings: SettingsService,
    public injector: Injector,
    public translator: TranslatorService,
    private appService: AppService,
    private Router: Router
  ) {
    this.selectedLanguage = this.translator.getLanguageInUse();
  }
  roleButtonVisible: boolean = false;

  get role() {
    return localStorage.getItem("role");
  }

  ngOnInit() {


    this.isNavSearchVisible = false;

    var ua = window.navigator.userAgent;
    if (ua.indexOf("MSIE ") > 0 || !!ua.match(/Trident.*rv\:11\./)) {
      this.fsbutton.nativeElement.style.display = "none";
    }

    this.router = this.injector.get(Router);

    this.router.events.subscribe((val) => {
      window.scrollTo(0, 0);
      this.navCollapsed = true;
    });

    const userLogged = JSON.parse(localStorage.getItem("userLogged"))
    this.roleButtonVisible = userLogged.roles.length > 1
  }

  toggleUserBlock(event) {
    event.preventDefault();
    this.userblockService.toggleVisibility();
  }

  openNavSearch(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setNavSearchVisible(true);
  }

  setNavSearchVisible(stat: boolean) {
    this.isNavSearchVisible = stat;
  }

  getNavSearchVisible() {
    return this.isNavSearchVisible;
  }

  toggleOffsidebar() {
    this.settings.toggleLayoutSetting("offsidebarOpen");
  }

  toggleCollapsedSideabar() {
    this.settings.toggleLayoutSetting("isCollapsed");
  }

  isCollapsedText() {
    return this.settings.getLayoutSetting("isCollapsedText");
  }

  toggleFullScreen(event) {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
  }

  getLangs() {
    return this.translator.getAvailableLanguages();
  }

  setLang(value) {
    this.translator.useLanguage(value);
    this.selectedLanguage = value;
  }

  logout() {
    console.log("LOGOUT");
    this.settings.sessionExpired();
    this.router.navigate(["/login/0"]);
  }

  private getUsers(search: string): Promise<void> {
    const options: IS1InputSelectItem[] = [];
    return this.appService
      .getAll(`/api/admin/users?search=${search}`)
      .pipe(map((res) => res.results))
      .toPromise()
      .then((usersFound: User[]) => {
        usersFound.forEach((el) => {
          const option: IS1InputSelectItem = {
            code: el.firstName,
            label: el.lastName + " " + el.firstName,
            id: el.id,
          };
          options.push(option);
        });
        this.usersFound = options;
      });
  }

  onNameValueChange($event: any) {
    this.usersFound = [];
    const newValue = $event.term;
    if (newValue !== this.nameValue) {
      this.nameValue = newValue;

      if (this.nameValue.length > 1) {
        this.getUsers(this.nameValue);
      } else {
        this.usersFound = [];
      }
    }
  }

  selectedUser($event) {
    console.log($event);
    this.router.navigate([`/calendario/${$event.id}`]);
  }

  changeRole() {
    this.Router.navigate(['/selectrole'])
  }
}
