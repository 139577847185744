<div class="home-container mt">
  <div class="rounded-4 rounded border border-black bg-white p-3 pb-4 col d-flex flex-column align-items-center">
    <div
      class="row w-100 d-flex justify-content-between align-items-center mb-3 border-bottom"
    >
      <h3 class="mb-2">Calendario</h3>

      <div class="d-flex flex-row-reverse align-items-center">
        <app-my-button
          text="Crea Festività"
          (onClick)="GoToFestivita()"
          icon="fa fa-plus"
          class="mb-3 ml-2"
        >
        </app-my-button>

        <ng-container>
          <app-my-button
            *ngIf="!isLoading; else spinner"
            text="Invia Promemoria"
            (onClick)="sendReminder()"
            icon="fa-solid fa-bell"
            class="mb-3"
          >
          </app-my-button>
          <ng-template #spinner>
            <div class="spinner-border text-primary m-0" role="status"></div>
          </ng-template>
        </ng-container>
      </div>
    </div>
    <main class="container w-100 d-flex">
      <!-- <div class="col-2 bg-white">
        <div
          class="d-flex flex-row align-items-center justify-content-between mt-3 pt-4 pb-4 undefined"
        >
          <h3 class="text-center w-100">Indecisi</h3>
          <button
            class="fa-regular fa-bell bell fa-xl text-danger bg-transparent border-0"
            (click)="sendReminder()"
            aria-label="Reminder"
            id="reminderIcon"
            *ngIf="!isLoading"
          ></button>
          <div
            class="spinner-border spinner-border-sm text-start text-info"
            role="status"
            *ngIf="isLoading"
          ></div>
        </div>

        <ul
          class="list-group w-100 py-3 justify-content-center py-3"
          id="undefinedUsersList"
        >
          <li
            class="list-group-item mt-1 w-75"
            *ngFor="let user of undefinedUsers"
          >
            {{ user.label }}
          </li>
        </ul>
      </div> -->

      <section class="w-100 container mt-3">
        <!-- first row (weeks and users filters)-->
        <div
          class="d-flex flex-row justify-content-between row w-100 py-3 m-0 t-header"
        >
          <div class="col-3">
            <app-weekdays
              (weekChanged)="handleWeekChanged($event)"
            ></app-weekdays>
          </div>
          <div class="col-9 d-flex justify-content-end p-0">
            <div class="w-75 px-3">
              <s1-input-select
                [itemsList]="usersList"
                (onSelect)="filterByUser($event)"
                #selectedUser
                [placeholder]="'Elenco Dipendenti'"
                style="color: white;"
              ></s1-input-select>
            </div>
          </div>
        </div>
        <div class="bg-white">
          <s1-table #table [onlinePagination]="false">
            <thead class="col" header>
              <!-- weekdays -->
              <tr class="d-flex w-100 header">
                <th class="col-3 user-names header"><h4>DIPENDENTE</h4></th>
                <th
                  *ngFor="let day of weekdays; let i = index"
                  class="col header"
                >
                  <h4 class="text-center">{{ weekdaysName[i] }}</h4>
                  <p class="text-center">{{ day }}</p>
                </th>
              </tr>
            </thead>

            <tbody class="p-0" body>
              <!-- status and user names -->
              <tr *ngFor="let user of table.config.rows" class="d-flex w-100">
                <td
                  class="col-3 user-names header  d-flex justify-content-between"
                  
                >
                <p class="td-href" (click)="redirect(user.id)">
                  {{ user.label }}
                </p>
                  <i
                    class="fa-solid fa-calendar-xmark emoji"
                    *ngIf="isUserUndefined(user)"
                  ></i>
                </td>
                <td
                  *ngFor="let day of weekdays"
                  class="col text-white"
                  [ngClass]="
                    'bg-' + toLowerCase(getUserStatus(day, user.id)) + '-color'
                  "
                >
                  {{ getUserStatus(day, user.id) }}
                </td>
              </tr>
            </tbody>
          </s1-table>
        </div>
      </section>
    </main>
  </div>
</div>
