<div class="d-flex justify-content-center align-items-center flex-column h-100 background">
  <div class="d-flex flex-row justify-content-center align-items-center">
    <div
      *ngFor="let role of roles"
      class="bg-white shadow rounded-5 mx-2 pt-3 d-flex justify-content-center align-items-center flex-column type-card"
      style="width: 30vw"
      (click)="goToHome(role)"
    >
      <img
        class="img-fluid"
        src="assets/img/roles/{{ role | lowercase }}.jpg"
        alt="{{ role | lowercase }}"
      />

      <h3 class="m-0 mb-5 p-0 text-dark text-md">{{ role }}</h3>
    </div>
  </div>
</div>
