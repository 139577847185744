import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RoleService } from '../services/role-service';
import { menu } from '../../routes/menu';

@Injectable()
export class MenuService {


  ngOnInit(){
    

  }
}
