import { Component, Output, EventEmitter, ViewChild, TemplateRef, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

export interface IS1Modal {
  modal: S1Modal;
  close()
}

export enum S1ModalSizes {
  SM = 'modal-sm',
  MD = '',
  LG = 'modal-lg',
  XL = 'modal-xl'
}

@Component({
  selector: 's1-modal',
  templateUrl: './s1-modal.component.html',
  styleUrls: ['./s1-modal.component.scss']
})
export class S1Modal {

  @Input() title: string;
  @Output() onClose = new EventEmitter();
  @Input() deleteUser : boolean = false

  @ViewChild('modalTemplate') public modalTemplate: TemplateRef<any>;
  modalRef: BsModalRef;

  constructor(private modalService: BsModalService) {}

  open(size: S1ModalSizes = S1ModalSizes.LG) {
    this.modalRef = this.modalService.show(this.modalTemplate, { class: size });
  }

  close() {
    this.modalRef.hide();
    this.onClose.emit();

    if (this.deleteUser == true) {
      localStorage.removeItem('userSelected');
    }
}

}