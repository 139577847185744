import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { RouteService } from './route.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private routeService: RouteService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const value = route.data['value'];
    return this.checkCondition(value);
  }

  private checkCondition(value: any): boolean {
    if(value === 'reference') {
      return this.routeService.checkReference();
    } else if (value === 'admin') {
      return this.routeService.checkAdmin();
    } else {
      return this.routeService.checkRole();
    }
  }
}