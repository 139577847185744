<ng-container [ngSwitch]="modalType">
  <!-- CREAZIONE DELLO USER CAMPI DELLE FORM VUOTE -->
  <ng-container *ngSwitchCase="'create'">
    <div class="container">
      <!-- Content for create modal -->
      <div class="row mb-4">
        <div class="col-4">
          <app-avatar [name]="''" size="110"></app-avatar>
        </div>
        <div class="col-8 align-center">
          <s1-input-text
            [control]="form.controls.username"
            [label]="'Username'"
            [placeholder]="'ex. Mario.Rossi'"
            [showSearch]="false"
            class="mt-3"
          ></s1-input-text>
          <div class="custom-margin"></div>
          <div class="row mt-3">
            <s1-input-text
              [control]="form.controls.firstName"
              [label]="'Nome:'"
              [placeholder]="'ex. Mario'"
              [showSearch]="false"
              class="mt-3 col-6"
            ></s1-input-text>
            <s1-input-text
              [control]="form.controls.lastName"
              [label]="'Cognome:'"
              [placeholder]="'ex. Rossi'"
              [showSearch]="false"
              class="mt-3 col-6"
            ></s1-input-text>
          </div>
        </div>
      </div>
      <div class="row mb-3 align-items-center">
        <s1-input-text
          [control]="form.controls.email"
          [label]="'Email:'"
          [placeholder]="'ex. mariorossi@gmail.com'"
          [showSearch]="false"
          class="col-8"
        ></s1-input-text>
        <s1-input-select
          [itemsList]="roles"
          [control]="form.controls.roles"
          [multiple]="true"
          [label]="'Roles:'"
          [showSearch]="false"
          class="col-4"
        ></s1-input-select>
      </div>
      <br />

      <div class="d-flex justify-content-end">
        <app-my-button
          text="Crea Utente"
          (onClick)="createUser()"
          icon="fa fa-plus"
        >
        </app-my-button>
      </div>
    </div>
  </ng-container>

  <!-- MODIFICA DELLO USER -->
  <ng-container *ngSwitchCase="'update'">
    <div class="container">
      <!-- Content for update modal -->
      <div class="row mb-4">
        <div class="col-4">
          <app-avatar [name]="user.firstName" size="110"></app-avatar>
        </div>
        <div class="col-8 align-center">
          <s1-input-text
            [control]="form.controls.username"
            [label]="'Username'"
            [showSearch]="false"
            class="mt-3"
          ></s1-input-text>
          <div class="custom-margin"></div>
          <div class="row mt-3">
            <s1-input-text
              [control]="form.controls.firstName"
              [label]="'Nome:'"
              [placeholder]="'ex. Mario'"
              [showSearch]="false"
              class="mt-3 col-6"
            ></s1-input-text>
            <s1-input-text
              [control]="form.controls.lastName"
              [label]="'Cognome:'"
              [placeholder]="'ex. Rossi'"
              [showSearch]="false"
              class="mt-3 col-6"
            ></s1-input-text>
          </div>
        </div>
      </div>
      <div class="row mb-3 align-items-center">
        <s1-input-text
          [control]="form.controls.email"
          [label]="'Email:'"
          [placeholder]="'ex. mariorossi@gmail.com'"
          [showSearch]="false"
          class="col-8"
        ></s1-input-text>
        <s1-input-text
          [control]="form.controls.status"
          [label]="'Status:'"
          [placeholder]="''"
          [showSearch]="false"
          [readonly]="true"
          class="col-4"
        ></s1-input-text>
      </div>
      <div class="align-items-center pl-0 d-flex col-12">
        <s1-input-select
          [itemsList]="roles"
          [multiple]="true"
          [control]="form.controls.roles"
          [label]="'Roles:'"
          [showSearch]="false"
          class="col-7 pl-0"
        ></s1-input-select>
        <p class="text-left mx-3 col-4">Ruoli già assegnati {{ user.roles }}</p>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-5">
      <app-my-button
        text="Annulla"
        (onClick)="closeModal()"
        icon="fa fa-trash-alt"
        bgColor="red-color"
        class="mr-3"
      >
      </app-my-button>
      <app-my-button
        text="Modifica"
        (onClick)="updateUser()"
        icon="fa fa-check"
        bgColor="green-color"
      >
      </app-my-button>
    </div>
  </ng-container>

  <!-- MOSTRA LE INFORMAZIONI DELLO USER -->
  <ng-container *ngSwitchCase="'show'">
    <div class="container">
      <!-- Content for show modal -->
      <div class="row mb-4">
        <div class="col-4">
          <app-avatar [name]="user.firstName" size="110"></app-avatar>
        </div>
        <div class="col-8 align-center">
          <s1-input-text
            [control]="form.controls.username"
            [label]="'Username'"
            [showSearch]="false"
            class="mt-3"
          ></s1-input-text>
          <div class="custom-margin"></div>
          <div class="row mt-3">
            <s1-input-text
              [control]="form.controls.firstName"
              [label]="'Nome:'"
              [placeholder]="'ex. Mario'"
              [showSearch]="false"
              class="mt-3 col-6"
            ></s1-input-text>
            <s1-input-text
              [control]="form.controls.lastName"
              [label]="'Cognome:'"
              [placeholder]="'ex. Rossi'"
              [showSearch]="false"
              class="mt-3 col-6"
            ></s1-input-text>
          </div>
        </div>
      </div>
      <div class="row mb-3 align-items-center">
        <s1-input-text
          [control]="form.controls.email"
          [label]="'Email:'"
          [placeholder]="'ex. mariorossi@gmail.com'"
          [showSearch]="false"
          class="col-8"
        ></s1-input-text>
        <s1-input-text
          [control]="form.controls.status"
          [label]="'Status:'"
          [placeholder]="''"
          [showSearch]="false"
          class="col-4"
        ></s1-input-text>
      </div>
      <div class="d-flex col-9 pl-0 align-items-center">
        <s1-input-text
          [control]="form.controls.roles"
          [label]="'Roles:'"
          [showSearch]="false"
          class=""
        ></s1-input-text>
        <p class="text-left mx-3">Ruoli già assegnati: {{ user.roles }}</p>
      </div>
    </div>
  </ng-container>

  <!-- MODALE PER LA ELIMINAZIONE DELLO USER -->

  <ng-container *ngSwitchCase="'delete'">
    <div>
      <!-- Content for delete modal -->
      <p class="text-md">
        Sei sicuro di voler disabilitare l'utente
        <strong>{{ user.firstName }} {{ user.lastName }}</strong
        >?
      </p>
      <br />
      <p>
        <strong>
          Attenzione: l'utente continuerà ad essere visualizzabile all'interno
          della lista, ma verrà disabilitato in modo permanente.
        </strong>
      </p>
      <br />
      <br />
      <div class="d-flex align-items-center justify-content-between">
        <s1-button
          [type]="'BACK'"
          paddingX="4rem"
          (click)="closeModal()"
          [label]="'Indietro'"
        ></s1-button>
        <s1-button
          [type]="'DELETE'"
          paddingX="4rem"
          (click)="deleteUser(user.id)"
          [label]="'Elimina'"
        ></s1-button>
      </div>
    </div>
  </ng-container>

<!-- MODAL REFERENCE -->
  <ng-container *ngSwitchCase="'editReference'">
    <div class="container">
      <div class="row mb-4">
        <div class="col-4">
          <app-avatar [name]="user.firstName" size="110"></app-avatar>
        </div>
        <div class="col-8 align-center">
          <s1-input-text
            [control]="form.controls.username"
            [label]="'Username'"
            [showSearch]="false"
            class="mt-3"
          ></s1-input-text>
          <div class="custom-margin"></div>
          <div class="row mt-3">
            <s1-input-text
              [control]="form.controls.firstName"
              [label]="'Nome:'"
              [placeholder]="'ex. Mario'"
              [showSearch]="false"
              class="mt-3 col-6"
            ></s1-input-text>
            <s1-input-text
              [control]="form.controls.lastName"
              [label]="'Cognome:'"
              [placeholder]="'ex. Rossi'"
              [showSearch]="false"
              class="mt-3 col-6"
            ></s1-input-text>
          </div>
        </div>
      </div>
      <div class="row mb-3 align-items-center">
        <s1-input-text
          [control]="form.controls.email"
          [label]="'Email:'"
          [placeholder]="'ex. mariorossi@gmail.com'"
          [showSearch]="false"
          class="col-8"
        ></s1-input-text>
        <s1-input-text
          [control]="form.controls.status"
          [label]="'Status:'"
          [placeholder]="''"
          [showSearch]="false"
          class="col-4"
        ></s1-input-text>
      </div>
      <div class="align-items-center pl-0 d-flex col-12">
        <s1-input-select
          [itemsList]="roles"
          [multiple]="true"
          [control]="form.controls.roles"
          [label]="'Gruppo:'"
          [showSearch]="false"
          class="col-7 pl-0"
        ></s1-input-select>
        <p class="text-left mx-3 col-4">Dipendenti assegnati {{ user.roles }}</p>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-5">
      <app-my-button
        text="Annulla"
        (onClick)="closeModal()"
        icon="fa fa-trash-alt"
        bgColor="red-color"
        class="mr-3"
      >
      </app-my-button>
      <app-my-button
        text="Modifica"
        (onClick)="updateUser()"
        icon="fa fa-check"
        bgColor="green-color"
      >
      </app-my-button>
    </div>
  </ng-container>
</ng-container>
