<ng-container *ngIf="loading; else elseTemplate">
  <div class="d-flex align-items-center justify-content-center my-5">
    <div class="ball-pulse">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <div class="table-responsive rounded-3">
    <table class="table"
      [ngClass]="{'table-hover': hoverStyle, 'table-striped': striped }">
      <ng-content select="[header]"
        *ngIf="config.totalData > 0"></ng-content>
      <ng-content select="[body]"></ng-content>
    </table>
  </div>
  <div *ngIf="config.totalData == 0"
    class="w-100 text-center mt-2">
    <h4>{{ 's1.table.noElements' | translate }}</h4>
  </div>
  <div *ngIf="paginationVisible" [hidden]="config.totalData == 0"
    class="w-100 text-center mt-4">
    <s1-pagination #pagination
      [table]="config"
      [withReload]="withReload"
      [onlinePagination]="onlinePagination"
      (pageChanged)="paginationUpdated()"
      (handleReload)="onReload()"></s1-pagination>
  </div>
</ng-template>
