<div class="container-fluid" *ngFor="let usr of usersList">
  <div
    class="card w-100 d-flex flex-row align-items-center justify-content-between my-2"
  >
    <div class="d-flex flex-row align-items-center justify-content-between">
      <!-- AVATAR -->
      <app-avatar
        [name]="usr.firstName"
        [size]="60"
        class="img-fluid ml-2 mr-4"
      ></app-avatar>
      <!-- DIV INFORMAZIONI  -->
      <div class="d-flex flex-column align-items-start justify-content-center">
        <h5 class="card-title text-start m-0">
          {{ usr.firstName }} {{ usr.lastName }}
        </h5>
        <p class="card-text text-start m-0">{{ usr.email }}</p>
      </div>
    </div>

    <!-- DIV BOTTONI -->
    <div
      class="d-flex flex-row justify-content-end align-items-center p-0 m-0"
      style="gap: 10px"
    >
      <button
        type="button"
        class="btn bg-blue-color text-white"
        (click)="linkToProfile(usr)"
      >
        <i class="icon-user"></i> Visualizza
      </button>
      <button
        type="button"
        class="btn btn-secondary text-blue-color border-blue-color"
        (click)="linkToCalendar(usr)"
      >
        <i class="icon-calendar"></i> Calendario
      </button>
    </div>
  </div>
</div>
