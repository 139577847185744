import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { toUpper } from "lodash";
import { BsModalRef } from "ngx-bootstrap/modal";
import { RoleService } from "@app/core/services/role-service";
import { CalendarEvent } from "@app/core/interfaces/admin.interface";
import swal from "sweetalert2";
import { error } from "console";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "app-create-calendarevent-modal",
  templateUrl: "./create-calendarevent-modal.component.html",
  styleUrls: ["./create-calendarevent-modal.component.scss"],
})
export class CreateCalendareventModalComponent implements OnInit {
  @Output() onClose = new EventEmitter<void>();
  @Input() date: string;
  @Input() userId: string;
  @Input() calendarEvent: CalendarEvent;
  @Output() eventCreated = new EventEmitter<void>();

  message: string;
  ore: number;
  selectedStatus: string;
  isNewEvent: boolean = true;

  constructor(private roleService: RoleService, public modalRef: BsModalRef, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  get role(): string{
    return localStorage.getItem("role")
  }
  
  initializeForm(): void {
    if (this.calendarEvent) {
      this.isNewEvent = false;
      this.selectedStatus = this.calendarEvent.calendarStatusType;
      this.message = this.calendarEvent.message;
      this.ore = this.calendarEvent.ferieAndPermessi.ore;
    }
  }

  toggleSelectedStatus(status: string): void {
    if (this.selectedStatus === status) {
      this.selectedStatus = null;
    } else {
      this.selectedStatus = status;
    }
  }

  onSubmit(): void {
    const formData = this.prepareFormData();

    if (this.isNewEvent) {
      this.createCalendarEvent(formData);
    } else {
      this.updateCalendarEvent(formData);
    }
  }

  prepareFormData(): any {
    const formData: any = {
      calendarDay: this.date,
      calendarStatusType: toUpper(this.selectedStatus),
      message: this.message,
      ore: this.ore,
    };
    return formData;
  }

  createCalendarEvent(formData: any): void {
    this.roleService.postNewCalendar(formData, this.userId).subscribe((response) => {
        if(response.outcome.success){
          this.toastr.success('Giornata creata con successo', 'SUCCESSO');
          this.eventCreated.emit(); 
          this.close();
        }
    }, (error) => {
      this.toastr.error(error.data, 'ERROR');
      this.close();
    });
  }

  updateCalendarEvent(formData: any): void {
    this.roleService.editCalendarData(this.calendarEvent.id, formData).subscribe((response) => {
      if(response.outcome.success){
        this.toastr.success('Giornata modificata con successo', 'SUCCESSO');
        this.eventCreated.emit(); 
        this.close();
      }
  }, (error)=> {
      this.toastr.error(error.data, 'ERROR');
    this.close()
  });
  }

  close(): void {
    this.modalRef.hide();
    this.onClose.emit();
  }
}
