import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { AppService } from '@app/core/services/base.service';
import { S1InputSelect, S1Table } from '@app/s1';
import { toLower } from 'lodash';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { AdminService } from '@app/core/services/admin-services';
import { RoleService } from '@app/core/services/role-service';
@Component({
  selector: 'app-richieste',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements AfterViewInit {
  @ViewChild("table") table: S1Table;
  @ViewChild("selectedUser") selectedUser: S1InputSelect;

  status: string = "";
  calendar: any[] = [];
  weekdays: string[] = [];
  weekdaysName: string[] = ["LUN", "MAR", "MER", "GIO", "VEN"];
  usersList: any[] = [];
  startMonth: string = "";
  undefinedUsers: any[] = []; // Initialize undefinedUsers array
  isLoading: boolean = false;
  selectedUserId: string = "";
  filteredUsers: any[] = []

  constructor(
    private roleService: RoleService,
    private router: Router
  ) { }

  ngAfterViewInit() {
    // Reminder styles
    document.getElementById("reminderIcon").addEventListener("mouseover", () => {
      document.getElementById("reminderIcon").className = "fa fa-bell bell bg-transparent border-0 text-danger";
    });
    document.getElementById("reminderIcon").addEventListener("mouseleave", () => {
      document.getElementById("reminderIcon").className = "fa-regular fa-bell bell bg-transparent border-0 text-danger";
    });
  }

  getWeekDays(startDate) {
    for (let i = 0; i < 5; i++) {
      this.weekdays.push(this.addDays(startDate, i));
    }
  }

  handleWeekChanged(event) {
    // Handle changes in week selection
    const startDate = `${event.start_year}-${event.start_month}-${event.start_day_number}`;
    const endDate = `${event.end_year}-${event.end_month}-${event.end_day_number}`;
    this.startMonth = `${event.start_year}-${event.start_month}`;

    this.weekdays = [];
    this.getWeekDays(new Date(startDate));

    //this.roleService.getList(`/api/admin/calendar/week?startDate=${startDate}&endDate=${endDate}`, null)
    this.roleService.getWeek(startDate, endDate)

      .subscribe((response: any) => {
        this.calendar = response.results;
        this.getUsersList();
      });
  }

  addDays(date: Date, daysToSum: number) {
    // Add days to a date and return formatted date
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + daysToSum);
    const year = newDate.getFullYear();
    const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
    const day = newDate.getDate().toString().padStart(2, '0');
    return `${day}-${month}-${year}`;
  }

  getUsersList() {
    // Retrieve list of users
    this.roleService.getUsers()
      .subscribe(data => {
        this.usersList = data.results.map(user => {
          return {
            id: user.id,
            code: user.id,
            label: `${user.firstName} ${user.lastName}`
          };
        });
        this.initializeUndefinedUsers(); // Initialize undefinedUsers after fetching users
        this.updateTableData();
      });
  }

  initializeUndefinedUsers() {
    // Initialize undefinedUsers with all users
    this.undefinedUsers = [...this.usersList];
  }

  updateTableData() {
    // Update table data based on selected user filter
    if (this.selectedUserId) {
      this.filteredUsers = this.usersList.filter(user => user.id === this.selectedUserId);
      this.table.updateData(this.filteredUsers);
    } else {
      this.table.updateData(this.usersList);
    }
  }

  getUserStatus(day: string, userId: string): string {
    // Get user status for a specific day
    let status = null;
    const [dayPart, monthPart, yearPart] = day.split('-');
    const formattedDay = `${yearPart}-${monthPart}-${dayPart}`;
    

    for (const calendarDay of this.calendar) {
      if (calendarDay.calendarDay === formattedDay && calendarDay.user.id === userId) {
        console.log("ciiwiewe")
        status = calendarDay.calendarStatusType;
        break;
      }
    }

    if (!status) {
      // Check if the user has status defined for all days of the month
      const allDaysDefined = this.weekdays.every(weekday => {
        const foundDay = this.calendar.find(item => item.calendarDay === weekday && item.user.id === userId);
        return foundDay !== undefined;
      });

      if (allDaysDefined) {
        // Remove user from undefinedUsers if status is defined for all days
        this.removeUserFromUndefined(userId);
      } else {
        // Add user to undefinedUsers if status is not defined for all days
        this.addUserToUndefined(userId);
      }
    }

    return status;
  }

  removeUserFromUndefined(userId: string) {
    // Remove user from undefinedUsers array
    this.undefinedUsers = this.undefinedUsers.filter(user => user.id !== userId);
  }

  addUserToUndefined(userId: string) {
    // Add user to undefinedUsers array if not already present
    if (!this.undefinedUsers.some(user => user.id === userId)) {
      const userToAdd = this.usersList.find(user => user.id === userId);
      if (userToAdd) {
        this.undefinedUsers.push(userToAdd);
      }
    }
  }

  sendReminder() {
    // Send reminder emails to users with undefined status
    this.isLoading = true;
    this.roleService.getUsers()
      .subscribe((data: any) => {
        const undefinedIds = this.undefinedUsers.map(user => user.id);
        const list = data.results.filter(user => undefinedIds.includes(user.id));
        const body = {
          userList: list,
          month: this.startMonth
        };
        console.log(body);
        this.roleService.postPeopleReminder(body)
        .subscribe(response => {
            swal.fire({
              icon: "success",
              title: "SUCCESSO",
              text: "Email inviata con successo!",
              confirmButtonColor: '#10509C',
            });
            this.isLoading = false;
          }, (error) => {
            console.log(error);
            swal.fire({
              icon: "error",
              title: "ERRORE",
              text: "Errore durante l'invio dell'email, riprova più tardi.",
              confirmButtonColor: '#10509C',
            });
            this.isLoading = false;
          });
      });
  }

  toLowerCase(string: string) {
    // Convert string to lowercase
    return toLower(string);
  }

  filterByUser(event) {
    // Filter users based on selected user
    if (event.id !== undefined) {
      this.selectedUserId = event.id;
      this.filteredUsers = this.usersList.filter(user => user.id === event.id);
    } else {
      this.selectedUserId = "";
      this.filteredUsers = [];
    }
    this.updateTableData();
  }

  redirect(id) {
    // Redirect to calendar details
    this.router.navigate([`/calendario/${id}`]);
  }

  GoToFestivita() {
    // Navigate to holiday page
    this.router.navigate(['festivita']);
  }

  isUserUndefined(user) {
    // Check if user is in undefinedUsers list
    return this.undefinedUsers.some(u => u.id === user.id);
  }
}
