<tr class="row border-bottom border-1" [ngStyle]="{
    backgroundColor:
      request.requestType == 'FERIE'
        ? 'rgba(255, 209, 71, 0.05)'
        : 'rgba(103, 186, 225, 0.05)'
  }">

  <!-- TIPOLOGIA -->
  <td class="col-1 d-flex justify-content-center align-items-center m-0 p-0">
    <i class="icon-large button-icons" [ngClass]="{
        'fa-solid fa-umbrella-beach ': request.requestType == 'FERIE',
        'fa-regular fa-calendar-check': request.requestType != 'FERIE'
      }" [ngStyle]="{
        color:
          request.requestType == 'FERIE'
            ? 'rgb(225, 167, 35)'
            : 'rgba(0, 163, 211)'
      }"></i>
  </td>

  <!-- DIPENDENTE -->
  <td class="col-5">
    <div class="d-flex flex-row justify-content-start align-items-center " >
      <app-avatar [name]="request.user.firstName + ' ' + request.user.lastName" [size]="30" class="mr-3"></app-avatar>
      <div class="d-flex flex-column justify-content-center align-items-start">
        <h4 class="m-0 text">
          {{ request.user.firstName }} {{ request.user.lastName }}
        </h4>
        <p class="m-0 text-sm">{{ request.user.email }}</p>
      </div>
    </div>
  </td>

  <!-- STATO -->
    <td class="col-2" [ngSwitch]="request.requestStatus">
        <div class="d-flex flex-column align-items-start">
            <p *ngSwitchCase="'PENDING'" class="m-0 p-0 text-blue-color">
                <b>IN ATTESA</b>
            </p>
            <p *ngSwitchCase="'REJECTED'" class="m-0 p-0 text-danger">
                <strong>RIFIUTATA</strong>
            </p>
            <p *ngSwitchCase="'APPROVED'" class="m-0 p-0 text-green-color">
                <b>APPROVATA</b>
            </p>

            <p *ngIf="request.approvalDateTime" class="m-0 p-0 small">
                il {{request.approvalDateTime | date : "dd/MM/yyyy"}}
            </p>
        </div>
    </td>

  <!-- DATE -->
  <td class="{{role !='ADMIN' && role !='REFERENCE' ? 'col-1 justify-content-start' : (request.requestType == 'FERIE' ? 'col-1 justify-content-start' : 'col-2 text-center justify-content-center')}} d-flex align-items-center ">
    <div *ngIf="request.requestType == 'FERIE'">
      <div>
        <strong>{{ request.startDate | date : "dd/MM/yyyy" }}</strong>
        <p></p>
      </div>
    </div>
   <div *ngIf="request.requestType != 'FERIE'" class=" justify-content-center">
      <strong class="m-0 p-0">
        {{ request.startDate | date : "MMMM  yyyy" : "" : "it" | uppercase }}
      </strong>
    </div>
  </td>

  <td class="{{role !='ADMIN' && role !='REFERENCE' ? 'col-1 d-flex' : (request.requestType == 'FERIE' ? 'col-1 d-flex' : 'd-none' )}}  align-items-center justify-content-start">
    <div *ngIf="request.requestType == 'FERIE'">
      <div>
        <strong>{{ request.endDate | date : "dd/MM/yyyy" }}</strong>
        <p></p>
      </div>
    </div>
  </td>

  <!-- VISUALIZZA -->
  <td class="col-1 justify-content-center d-flex align-items-center">
    <i class="fas fa-search fa-lg table-icons text-blue-color hover:cursor-pointer"
      *ngIf="request.requestStatus != 'REJECTED' && role==='ADMIN' || role ==='REFERENCE'" (click)="linkTo(request)"></i>
    <i class="fas fa-search fa-lg table-icons text-blue-color hover:cursor-pointer" (click)="linkTo(request)"
      *ngIf="role==='USER'" ></i>
  </td>

  <!-- OPERAZIONI -->
  <td class="col-1 justify-content-center d-flex align-items-center" *ngIf="role==='ADMIN' || role ==='REFERENCE'">
    <div *ngIf="request.requestStatus == 'PENDING'">
      <button class="btn btn-success p-1 button-dimensions text-white d-flex justify-content-center align-items-center" (click)="openModal(true, request, true)">
        <i class="fas fa-check fa-lg"></i>
      </button>
    </div>
    <div *ngIf="
        request.requestStatus == 'PENDING' && request.requestType == 'FERIE'
      ">
      <button [ngClass]="{'ml-2': request.requestStatus == 'PENDING', 'ml-0': request.requestStatus != 'PENDING'}" class="btn btn-danger p-1 button-dimensions text-white d-flex justify-content-center align-items-center" (click)="openModal(true, request, false)">
        <i class="fas fa-x fa-lg"></i>
      </button>
    </div>
  </td>
</tr>

<!-- MODAL PER ACCETTARE LE CHIUSURE MENSILI -->
<s1-modal #modal title="Chiusura mensile">
  <div class="justify-content-center">
    <p class="text-md mb-5">
      Sei sicuro di voler confermare il mese
      <strong> {{ request.startDate | date : "MMMM" : "" : "it" }}</strong> di
      <strong> {{ request.user.firstName }} {{ request.user.lastName }}</strong>?
    </p>
    <div class="d-flex flex-row justify-content-end">
      <app-my-button class="mr-2" text="ANNULLA" bgColor="blue-color" (click)="closeModal()"
        icon="fa fa-chevron-left fa-md"></app-my-button>
      <app-my-button text="APPROVA" bgColor="green-color" (click)="approveRequest(request.id)"
        icon="fa fa-check fa-md"></app-my-button>
    </div>
  </div>
</s1-modal>

<!-- MODAL PER ACCETTARE/RIFIUTARE LE FERIE -->

<app-ferie-requests-modal
    #approveReject
    [isApprove]="isApprove"
    [request]="request"
    (modalEvent)="modalEvent.emit($event)">
</app-ferie-requests-modal>
