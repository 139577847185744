import { AfterContentInit, AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { AppService } from "@app/core/services/base.service";
import { IS1InputSelectItem, S1Modal, S1ModalSizes, S1Table } from "@app/s1";
import { RolesEnum, User, Reference } from "@app/core/interfaces/admin.interface";
import { RoleService } from "@app/core/services/role-service";
import { userInfo } from "node:os"
import { Router } from "@angular/router";

@Component({
  selector: "app-dipendenti",
  templateUrl: "./dipendenti.component.html",
  styleUrls: ["./dipendenti.component.scss"],
})
export class DipendentiComponent implements OnInit, AfterViewInit {

  @ViewChild('table') table: S1Table;
  @ViewChild('modal') modal: S1Modal;

  modalType : string = 'show'
  allUsers: User[] = [];
  allReference: Reference[] = [];
  filteredUsers: User[] = [];
  filters: { type: string, value: any }[] = [];
  roleValue: { type: 'role', value: RolesEnum } | null = null;
  statusValue: { type: 'status', value: string } | null = null;
  nameValue: string | null = null;

  totFerie : number = 0
  totPermessi : number = 0

  roles: IS1InputSelectItem[] = [
    { code: "ADMIN", label: "Admin" },
    { code: "USER", label: "User" },
    { code: "REFERENCE", label: "Reference" },
  ];

  status: IS1InputSelectItem[] = [
    { code: "ACTIVE", label: "Attivo" },
    { code: "DISABLED", label: "Disattivo" },
    { code: "CREATED", label: "Creato" },
  ];

  reference: Reference = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    roles: [],
    status: "",
    username: "",
    password: "",
    totFerie: 0,
    totPermessi: 0
  };

  user: User = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    roles: [],
    status: "",
    username: "",
    password: "",
    totFerie: 0,
    totPermessi: 0
  };

  role : string = ''
  isCapogruppiPage : boolean = false

  constructor(private appService: AppService, private roleService : RoleService, private router: Router) {}

  async ngOnInit() {
    this.role = localStorage.getItem('role')
    this.roleService.setRole(this.role)
    this.loadData()
  }

  ngAfterViewInit() {
    this.isCapogruppiPage = this.router.url.includes('capogruppi');
    this.loadData()
    if (localStorage.getItem('userSelected')) {
      const user = JSON.parse(localStorage.getItem('userSelected'));
      this.openModal('show', user);
    }
  }

  loadData(params: any = null): void {
    if(this.isCapogruppiPage){
      this.appService.getList("/api/admin/capogruppi", params).subscribe((data) => {
        this.allUsers = data.results;
        this.table?.updateData(this.allUsers);
        this.updateFilters();
        this.table?.handleReload.emit()
      })
    } else {
      this.appService.getList("/api/admin/users", params).subscribe((data) => {
        this.allUsers = data.results;
        this.table?.updateData(this.allUsers);
        this.updateFilters();
        this.table?.handleReload.emit()
      })
    }
  }

  onRoleValueChange(newValue: string) {
    if (newValue) {
      this.roleValue = { type: 'role', value: newValue as unknown as RolesEnum };
    } else {
      this.roleValue = null;
    }
    this.updateFilters();
  }

  onStatusValueChange(newValue: string) {
    if (newValue) {
      this.statusValue = { type: 'status', value: newValue };
    } else {
      this.statusValue = null;
    }
    this.updateFilters();
  }

  onNameValueChange(newValue: string) {
    if (newValue) {
      this.nameValue = newValue;
      this.filters.push({ type: 'name', value: newValue });
    } else {
      this.nameValue = null;
    }
    this.updateFilters();
  }

  updateFilters() {
    this.filters = [];
    if (this.roleValue) {
      this.filters.push(this.roleValue);
    }
    if (this.statusValue) {
      this.filters.push(this.statusValue);
    }
    if (this.nameValue) {
      this.filters.push({ type: 'name', value: this.nameValue });
    }
    this.updateTable();
  }

  updateTable() {
    this.filteredUsers = this.allUsers;

    this.filters.forEach((filter) => {
      if (filter.type === 'status') {
        this.filteredUsers = this.filteredUsers.filter((user: User) => user.status === filter.value);
      }
      if (filter.type === 'role') {
        this.filteredUsers = this.filteredUsers.filter((user: User) => user.roles.includes(filter.value));
      }
      if (filter.type === 'name') {
        this.filteredUsers = this.filteredUsers.filter((user: User) =>
          user.firstName.toLowerCase().includes(filter.value.toLowerCase()) ||
          user.lastName.toLowerCase().includes(filter.value.toLowerCase()) ||
          user.username.toLowerCase().includes(filter.value.toLowerCase()) ||
          user.email.toLowerCase().includes(filter.value.toLowerCase()) 
        );
      }
    });

    this.table.updateData(this.filteredUsers);
  }

   openModal(type: string, user : User) {
    this.user = user
     if(!this.user){
         this.user = {
           id: "",
           firstName: "",
           lastName: "",
           email: "",
           roles: [],
           status: "",
           username: "",
           password: "",
           totFerie: 0,
           totPermessi: 0
         };
     }
    this.modalType = type
    this.modal.open(S1ModalSizes.LG)
   }

  closeModal(){
    this.modal.close()
  }

  reloadPage(){
    console.log("ciao")
    this.table.refreshAfterCreate()
    this.loadData()
  } 

  ngOnDestroy() {
    localStorage.removeItem('userSelected')
  }
}
