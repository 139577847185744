import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { LoggingService } from '../services/log.service';
import { AuthService } from '../services/auth.service';
import { environment } from '../../../environments/environment';
import { animation } from '@angular/animations';

@Injectable()
export class SettingsService {

    private user: any;
    private app: any;
    private layout: any;
    private errorTranslation: any;

    constructor(private translate: TranslateService, private router: Router, private baseService: AuthService, private logService: LoggingService) {

        this.errorTranslation = this.translate.get('error');

        // User Settings
        // -----------------------------------
        this.user = {
            name: '',
            job: '',
            picture: 'assets/img/user/02.jpg'
        };

        // App Settings
        // -----------------------------------
        this.app = {
            name: 'Soluzione 1',
            description: 'Gestione Presenze Soluzione1',
            year: ((new Date()).getFullYear()),
            version: environment.version,
            versionBE: "N/D",
            releaseDate: "N/D"
        };

        // Layout Settings
        // -----------------------------------
        this.layout = {
            isFixed: true,
            isCollapsed: false,
            isBoxed: false,
            isRTL: false,
            horizontal: false,
            isFloat: false,
            asideHover: false,
            theme: null,
            asideScrollbar: false,
            isCollapsedText: false,
            useFullLayout: false,
            hiddenFooter: false,
            offsidebarOpen: false,
            asideToggled: false,
            viewAnimation: 'ng-fadeInUp',
        };

    }

    getAppSetting(name) {
        return name ? this.app[name] : this.app;
    }
    getUserSetting(name) {
        return name ? this.user[name] : this.user;
    }
    getLayoutSetting(name) {
        return name ? this.layout[name] : this.layout;
    }

    setAppSetting(name, value) {
        if (typeof this.app[name] !== 'undefined') {
            this.app[name] = value;
        }
    }
    setUserSetting(name, value) {
        if (typeof this.user[name] !== 'undefined') {
            this.user[name] = value;
        }
    }
    setLayoutSetting(name, value) {
        if (typeof this.layout[name] !== 'undefined') {
            return this.layout[name] = value;
        }
    }

    toggleLayoutSetting(name) {
        return this.setLayoutSetting(name, !this.getLayoutSetting(name));
    }

    getInfoVersion() {
        this.baseService.getVersion(this.baseService.pathInfoVersion)
            .subscribe((response) => {
                this.setAppSetting('versionBE', response.version);
                this.setAppSetting('releaseDate', response.releaseDate);
                this.logService.log("Infos ", response, 300);
            },
                (error) => {
                    this.logService.log("Error", error, 200);
                }
            );

    }


    sessionExpired() {
        const username = localStorage.getItem('username');
        const password = localStorage.getItem('password');
        localStorage.clear();
        if (username && password) {
          localStorage.setItem('username', username);
          localStorage.setItem('password', password);
        }
        this.router.navigate(['/login/1']);
    }

    manageErrorMsg(outcome) {

        this.translate.get('error').subscribe( (text) => {
            this.errorTranslation = text;
        });

        //this.errorTranslation = this.translate.get('error');
        let message = "";
        console.log("TRANS", this.errorTranslation)
        console.log("cod", outcome)
        switch (outcome.code) {
            case '0001':
                message = this.errorTranslation.error_0001;
                break;
            case '0002':
                message = this.errorTranslation.error_0002;
                break;
            case '0003':
                message = this.errorTranslation.bad_credential;
                break;
            case '0004':
                message = this.errorTranslation.wrong_password;
                break;
            case '0005':
                this.sessionExpired();
                this.router.navigate(["/login/0"]);
                break;
            case '0006':
                message = this.errorTranslation.account_disabled;
                break;
            case '0007':
                this.sessionExpired();
                this.router.navigate(["/login/0"]);
                message = this.errorTranslation.auth_required;
                break;
            default:
                message = this.errorTranslation.generic_error;
                break;
        }
        return message;
    }

}
