import { SelectionType } from './../../../../core/interfaces/admin.interface';
import { saveAs } from 'file-saver/src/FileSaver.js';
import { userSelected } from './../../../../../environments/environment';
import { IS1AutocompleteResults } from './../../../../s1/services/s1-autocomplete.service';
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
} from "@angular/forms";
import { AppService } from "@app/core/services/base.service";
import { IS1InputSelectItem } from "@app/s1/components/s1-input-select/s1-input-select.component";
import { EventInput, EventSourceInput } from "@fullcalendar/core";
import { map } from "rxjs/operators";
import { RoleService } from "@app/core/services/role-service";
import { formatDate } from "@angular/common";
import { isObject } from "lodash";
import { DateRangePickerComponent } from "@app/newComponents/date-range-picker/date-range-picker.component";
import swal from 'sweetalert2';
import { date } from 'ngx-custom-validators-2';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "app-vacation",
  templateUrl: "./vacation.component.html",
  styleUrls: ["./vacation.component.scss"],
})
export class VacationComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit{

  @ViewChild("datePicker") datePicker: DateRangePickerComponent
  vacationsApproved: EventSourceInput = [];
  requestApproved: any[] = [];
  requests = [];
  userId: string;
  usersList: IS1InputSelectItem[] = [];

  statusSelected: string;
  name: string;
  startDate: string;
  endDate: string;
  userSelected: any;
  dateExpiredVacation: string;
  

  user: any;
  form : FormGroup
  constructor(
    private formBuilder: FormBuilder,
    private roleService : RoleService,
    private toastr: ToastrService
  ) {}

  async ngOnInit(): Promise<void> {
    const role = localStorage.getItem('role')
    this.roleService.setRole(role)
    await this.loadInitialData();

    const userVacation = localStorage.getItem('userVacation');
    if (userVacation) {
      this.onSearch();
    }
    localStorage.removeItem('userVacation');
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    const calendar = document.getElementById("calendar");
    if (calendar) {
      calendar.remove();
    }
  }

  onChange($event) {
    this.getVacationsFiltered();
    this.getRequest();
  }

  get role(): string{
    return localStorage.getItem("role")
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["startDate"] &&
      changes["startDate"].currentValue &&
      changes["endDate"] &&
      changes["endDate"].currentValue && 
      changes['requests'] &&
      changes['requests'].currentValue

    ) {
      this.getVacationsFiltered();
      this.getRequest()      
    }
  }

  private async loadInitialData() {
    await this.getRequest(),
    await this.getVacations(),
    await this.getUsers()
  }

  getSelectedRequestStartDate(): string | null {
    const selectedRequest = this.requests.find(request => request.selected);
    return selectedRequest ? selectedRequest.startDate : null;
  }

  private async getRequest(): Promise<void> {
    const vacationSelected = localStorage.getItem('vacationSelected');
    this.requests = [];
    return this.roleService.getRequestsFiltered('FERIE', null, null, 'PENDING', null, 'orderStartDate=ASC')
      .pipe(map((res) => res.results))
      .toPromise()
      .then((results) => {
        this.requests = [];
      
        const selectedRequest = results.find(request => request.id === parseInt(vacationSelected));
        const otherRequests = results.filter(request => request.id !== parseInt(vacationSelected));

        otherRequests.forEach(request => {
          request.selected = false;
        });

        if (selectedRequest) {
          selectedRequest.selected = true;
          this.requests.push(selectedRequest);
        }
  
        this.requests.push(...otherRequests);
  
        localStorage.removeItem('vacationSelected');
      });
  }

  getRequestsFilteredByUser() {
    return this.roleService.getRequestsFiltered('FERIE', this.startDate, this.endDate, 'PENDING', this.userId)
      .pipe(map((res) => res.results))
      .toPromise()
      .then((results) => {
        this.requests = results;
      });
  }
  
  selectedUser($event) {
    this.userId = $event.id;
    this.onSearch();
    this.getRequestsFilteredByUser();
  }

  onSearch() {
    this.getVacationsFiltered();
  }

  private getVacations(): Promise<void> {
    return this.roleService.getRequestsFiltered('FERIE', this.startDate, this.endDate, 'APPROVED')
      .pipe(map((res) => res.results))
      .toPromise()
      .then((results) => {
        const events: EventInput[] = [];
        results.forEach((request) => {
          const event: EventInput = {
            id: request.id,
            title: request.user.firstName + " " + request.user.lastName,
            start: request.startDate,
            end: this.addOneDay(request.endDate),
            allDay: true,
          };
          events.push(event);
          this.requestApproved.push(request);
        });
        this.vacationsApproved = events;
      });
  }
  
  private addOneDay(dateStr: string): string {
    const date = new Date(dateStr);
    date.setDate(date.getDate() + 1);
    return date.toISOString().split('T')[0];
  }
  
  private getVacationsFiltered(): Promise<void> {
    if (this.userId !== undefined) {
      return this.roleService.getRequestsFiltered('FERIE', this.startDate, this.endDate, 'APPROVED', this.userId)
        .pipe(map((res) => res.results))
        .toPromise()
        .then((results) => {
          const events: EventInput[] = [];
          results.forEach((request) => {
            const event: EventInput = {
              id: request.id,
              title: request.user.firstName + " " + request.user.lastName,
              start: request.startDate,
              end: this.addOneDay(request.endDate),
              allDay: true,
            };
            events.push(event);
            this.requestApproved.push(request);
          });
          this.vacationsApproved = events;
        });
    } else {
      this.getVacations();
    }
  }

  private async getUsers() {
    const options: IS1InputSelectItem[] = [];

    return this.roleService.getUsers().subscribe((users) => {
      const results = users.results

      results.forEach(el => {
        const option: IS1InputSelectItem = {
          code: el.firstName,
          label: el.firstName + " " + el.lastName,
          id: el.id,
        };
        options.push(option);
      });
      this.usersList = options;
      });
  }

  correctDate($event, operation: string) {
    const eventDate = new Date($event);
    switch (operation) {
      case "add":
        eventDate.setMonth(eventDate.getMonth() + 1);
        const formattedEndDate = eventDate.toISOString().slice(0, 7);
        this.endDate = formattedEndDate;
        break;
      case "sub":
        eventDate.setMonth(eventDate.getMonth() - 1);
        const formattedStartDate = eventDate.toISOString().slice(0, 7);
        this.startDate = formattedStartDate;
        break;
      default:
        console.error("Invalid operation");
    }
  }

  setStartDate($event) {
    this.correctDate($event, "sub");
  }

  setEndDate($event) {
    this.correctDate($event, "add");
    this.getVacationsFiltered();
  }
  
  initForm(){
    this.form = this.formBuilder.group({
      user :  new FormControl(`${this.user.firstName} ${this.user.lastName}`)
    })
  }

  isDateSelected: boolean = false;
  isDateValid: boolean = false;
  vacationStartDate: string = "";
  vacationEndDate: string = "";

  handleDateSelected(event: { start: Date, end: Date }) {
    this.isDateSelected = true
    this.isDateValid = event.start > new Date() && event.end > new Date() 
    console.log('Date selezionate:', event.start, event.end);
    this.vacationStartDate = formatDate(event.start, "yyyy-MM-dd", "it-IT") 
    this.vacationEndDate = formatDate(event.end, "yyyy-MM-dd", "it-IT") 

  }

  requestVacations(){
    console.log(this.vacationEndDate, this.vacationStartDate)

    const body = {
      startDate: this.vacationStartDate,
      endDate: this.vacationEndDate,
      requestType: "FERIE"
    }

    this.roleService.postRequest(body)
      .subscribe((response: any) => {
        console.log(response);
        if (response.outcome.success) {
          isObject(response.data) ?
              this.toastr.success('Richiesta inviata con successo', 'SUCCESSO') :
              this.toastr.error(response.data, 'ERRORE');

          this.getVacations();
          this.datePicker.clearDates();
          this.isDateSelected = false;
          this.getRequest();
        }
      });
  }

}
