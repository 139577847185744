<s1-card [selected]="request.selected">
  <div class="d-flex flex-row justify-content-between px-2">
    <div class="d-flex flex-row align-items-center w-100">
      <app-avatar
        class="mt-2 mr-5"
        [name]="request.user.firstName + ' ' + request.user.lastName"
      ></app-avatar>

      <div
        *ngIf="showNames else userTemplate"
        class="d-flex flex-column align-items-start justify-content-center">
        <h4 class="card-title m-0" >
          {{ request.user.firstName }} {{ request.user.lastName }}
        </h4>

        <h5 class="card-subtitle m-0 text-muted text-center">
          {{ request.startDate | date : "EEEE d MMM yyyy" : "" : "it" | titlecase }}
          <ng-container *ngIf="request.startDate !== request.endDate">
              <br/><i class="fas fa-chevron-down"></i><br/>
              {{ request.endDate | date : "EEEE d MMM yyyy" : "" : "it" | titlecase }}
          </ng-container>
        </h5>
      </div>
    
      <ng-template #userTemplate>
        <div
          class="d-flex align-items-center justify-content-between w-100 text-center">
            <h6 class="card-subtitle m-0 text-muted">
                {{ request.startDate | date : "EEEE d MMM yyyy" : "" : "it" | titlecase }}
                <ng-container *ngIf="request.startDate !== request.endDate">
                    <br/><i class="fas fa-chevron-down"></i><br/>
                    {{ request.endDate | date : "EEEE d MMM yyyy" : "" : "it" | titlecase }}
                </ng-container>
            </h6>
          <button class="btn btn-danger p-1 button-dimensions text-white d-flex justify-content-center align-items-center" (click)="openModal(false)">
            <i class="fas fa-x fa-lg"></i>
          </button>
      </div>
      </ng-template>
      
    </div>

    <div class="d-flex flex-row justify-content-center align-items-center"
      *ngIf="showButtons" >
      <button class="btn btn-success p-1 button-dimensions text-white d-flex justify-content-center align-items-center" (click)="openModal(true)">
        <i class="fas fa-check fa-lg"></i>
      </button>
      <button class="btn btn-danger p-1 button-dimensions text-white d-flex justify-content-center align-items-center ml-2" (click)="openModal(false)">
        <i class="fas fa-x fa-lg"></i>
      </button>
    </div>
  </div>
</s1-card>

<!-- MODAL PER ACCETTARE/RIFIUTARE LE FERIE -->

<app-ferie-requests-modal
  #approveReject
  (changes) = "sendChanges()"
  [request]="request"
  (changes)="sendChanges()"
  [isApprove]="isApprove"

></app-ferie-requests-modal>
