<tabset [justified]="true">
    <tab>
        <ng-template tabHeading>
            <div class="custom-tab-heading">
                <i class="icon-drawer"></i>
                <p> Notifiche </p>
            </div>
        </ng-template>
        <div class="parent-container">
            <div (click)="goToFestivita()" class="overflow-auto max-height-50">
                <app-notification-card [notifications]="notifications"></app-notification-card>
            </div>
        </div>
    </tab>
</tabset>