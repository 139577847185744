<!-- NORMAL VIEW -->
<div *ngIf="!isCollapsed" class="background-profile border-top d-flex align-items-center">
  <div class="d-flex flex-row align-items-center py-3 justify-content-start w-100">
    <div class="">
        <app-avatar class="mx-2 mb-3 " [name]="user.firstName + ' ' + user.lastName" [size]="50"></app-avatar>
    </div>
      <div class="d-flex flex-column overflow-hidden pr-3" style="max-width: calc(100% - 70px);">
          <strong class="text-truncate">{{user.firstName}} {{user.lastName}}</strong>
          <p class="text-truncate mb-0">{{user.email}}</p>
      </div>
  </div>
</div>

<!-- SMALL VIEW -->
<div *ngIf="isCollapsed" class="text-center pr-3 pb-1">
  <app-avatar class="avatar" [name]="'admin'" [size]="40"></app-avatar>
</div>
