<div class="row pt-4 mt-2 pb-3" >
  <!-- PRIMA SEZIONE -->
  <div class="col-lg-4" >
    <div class="jumbotron d-flex flex-column custom">
      <div class="w-100 ml-auto mr-auto" >
        <!-- HEADER -->
        <h3 class="mb-3">
          <i class="fas fa-hourglass-half m-1"></i> Richieste di Ferie
        </h3>
        <div class="scrollable-container d-flex flex-column flex-grow-1">
          <!-- LOOP DI RICHIESTE -->
            <div *ngIf="requests.length != 0">
              <div *ngFor="let request of requests">
                <app-single-request
                  [showButtons] = "role === 'ADMIN' || role === 'REFERENCE'"
                  [showNames] = "role === 'ADMIN' || role === 'REFERENCE'"
                  [request]="request"
                  (changes)="onChange($event)"
                ></app-single-request>
              </div>
          
            </div>
          

          <div *ngIf="requests.length == 0">
            <h4 class="text-center mt-4 label">Non ci sono richieste di ferie</h4>
          </div> 
        </div>
      </div>
    </div>
  </div>

  <!-- SECONDA SEZIONE -->
  <div class="col-lg-8">
    <div class="jumbotron d-flex flex-column custom">
      <div class="col">
        <div class="row m-1">
          <!-- INPUT -->
          <div class="col-12 text-center">
            <h2 class="m-auto">Il Calendario delle Ferie</h2>
          </div>
          <div class="mx-auto col-md-10 mt-4">
            <ng-container *ngIf="role==='ADMIN' || role === 'REFERENCE' else userForm">
              <form [formGroup]="form" #form>
                <s1-input-select
                  [label]="'Seleziona il dipendente'"
                  [itemsList]="usersList"
                  (onSelect)="selectedUser($event)"
                  [control]="user"
                >
                </s1-input-select>
              </form>
            </ng-container>
          </div>
        </div>
        <!-- CALENDARIO -->
        <div class="mt-2 calendar-container">
          <app-my-calendar
            class="mt-auto w-100"
            [events]="vacationsApproved"
            [startingMonth]="requests.length ? requests[0]?.startDate : ''"
            [isVacationSelected]="requests.length ? requests[0]?.selected : false"
            (startMonthSelected)="setStartDate($event)"
            (endMonthSelected)="setEndDate($event)"
            [height]="400"
            vacation="true"
          ></app-my-calendar>
          
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #userForm>
  <div class="d-flex flex-column align-items-center ">
    <div class="d-flex flex-row justify-content-center align-items-center date-picker-container rounded px-3">
      <label htmlFor="dateRangeSelector">Richiedi Ferie: </label>

      <app-date-range-picker [class]="'mx-3'" (dateRangeSelected)="handleDateSelected($event)" #datePicker>

      </app-date-range-picker>

      <app-my-button
        [text]="'Richiedi'" 
        [bgColor]="'blue-color'"
        [disabled]="!isDateValid"
        (onClick)="requestVacations()"
        ></app-my-button>
    </div>
    <small class="text-danger" *ngIf="isDateSelected && !isDateValid">La data deve essere nel futuro</small> 
  </div>
</ng-template>