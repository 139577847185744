import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AppService } from "@app/core/services/base.service";
import { formatDate } from "@angular/common";
import { EventInput, EventSourceInput } from "@fullcalendar/core";
import { toLower, toUpper } from "lodash";
import { S1Modal, S1ModalSizes } from "@app/s1";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
} from "@angular/forms";
import { isNullOrUndefined } from "@swimlane/ngx-datatable";
import { RoleService } from "@app/core/services/role-service";
import { Observable } from "rxjs";
import {
  CalendarEvent,
  CalendarEvents,
} from "@app/core/interfaces/admin.interface";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CreateCalendareventModalComponent } from "@app/newComponents/modals/create-calendarevent-modal/create-calendarevent-modal.component";
import { computeInnerRect } from "@fullcalendar/core/internal";
import {getCalendar} from '@angular/material/datepicker/testing/datepicker-trigger-harness-base';
interface User {
  id: string;
  code: number;
  label: string;
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];
  status: string;
  username: string;
}

@Component({
  selector: "app-calendar-dashboard",
  templateUrl: "./calendar-dashboard.component.html",
  styleUrls: ["./calendar-dashboard.component.scss"],
})
export class CalendarDashboardComponent implements OnInit {
  @ViewChild("select") select: ElementRef;
  @ViewChild("form") form: UntypedFormGroup;
  @ViewChild("message") message: ElementRef;
  @ViewChild('modal') modal: S1Modal;

  user: User = {
    id: "",
    code: 0,
    label: "",
    firstName: "",
    lastName:"",
    email:"",
    roles: [],
    status:"",
    username:"",
  };

  modalType : string = 'show'

  date = new Date();

  todayDate: string = formatDate(
    new Date().setDate(new Date().getDate()),
    "yyyy-MM-dd",
    "it-IT"
  );
  tomorrowDate: string = formatDate(
    new Date().setDate(new Date().getDate() + 1),
    "yyyy-MM-dd",
    "it-IT"
  );

  role = "";

  colors = {
    ufficio: "rgb(0, 163, 211)",
    remote: "rgb(48, 193, 146)",
    malattia: "rgb(193, 72, 60)",
    trasferta: "rgb(229, 137, 160)",
    ferie: "rgb(225, 167, 35)",
    festivita: "rgb(140, 114, 180)",
  };

  constructor(
    private appService: AppService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private roleService: RoleService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.role = localStorage.getItem('role');
    this.roleService.setRole(this.role);
    this.activatedRoute.paramMap.subscribe((param) => {
      const id = param.get('id');
      if (id) {
        this.getUserById(id);
      } else {
        this.getAllUsers();
      }
    });
  }

  showDashboard(event): void {
    this.selectedUser = event;
  }

  goBackToGeneral() {
    this.router.navigate(["calendario/:id"]);
    this.selectedUser = null;
  }
  openMailBox() {
    window.location.href = `mailto:${this.selectedUser.email}`;
  }
  redirect() {
    this.openModal('show', this.selectedUser);
    console.log(this.selectedUser)
  }

   openModal(type: string, user : User) {
    this.user = user
    this.modalType = type
    this.modal.open(S1ModalSizes.LG)
   }

  calendarRedirect() {
    this.selectedUser = null;
    this.router.navigate(["calendario/:id"]);
  }

  getClassForCalendarStatusType(statusType: string): string {
    if (!statusType) {
      return "bg-altro-color";
    }
    return `bg-${statusType.toLowerCase()}-color text-white`;
  }

  //! USERS
  usersList: User[] = [];

  getAllUsers(): void {
    this.appService.getList("/api/admin/users", null).subscribe((data: any) => {
      this.usersList = data.results.map((user) => {
        return {
          id: user.id,
          code: user.id,
          label: `${user.firstName} ${user.lastName}`,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          roles: user.roles,
          username: user.username,
        };
      });
    });
  }

  //! SELECTED USER
  selectedUser: User | null = null;

  getUserById(id): void {
    this.roleService.getSpecificUser(id).subscribe(
      (response: any) => {
        this.selectedUser = {
          id: response.id,
          code: response.id,
          label: `${response.firstName} ${response.lastName}`,
          firstName: response.firstName,
          lastName: response.lastName,
          email: response.email,
          roles: response.roles,
          status: response.status,
          username: response.username,
        };
        this.getCalendarevents();
      },
      (error) => {
        this.getAllUsers();
      }
    );
  }

  //! CALENDAREVENTS

  dataList: { key; value }[] = [];

  calendareventsMap: CalendarEvents;
  todayCalendarevent: CalendarEvent | null = null;
  tomorrowCalendarevent: CalendarEvent | null = null;
  selectedDate: string = formatDate(new Date(), "yyyy-MM", "it-IT");

  getCalendarevents(): CalendarEvents {
    this.roleService
      .getCalendarData(this.selectedDate, this.selectedUser.id)
      .subscribe((data: CalendarEvents) => {
        const [year, month] = this.selectedDate.split('-').map(Number);

        this.calendareventsMap = Object.keys(data).reduce((filteredData, key) => {
          filteredData[key] = data[key].filter((entry) => {
            const entryDate = new Date(entry.calendarDay);
            return entryDate.getMonth() + 1 === month && entryDate.getFullYear() === year;
          });
          return filteredData;
        }, {});

        console.log(this.selectedUser);
        this.valorizeCalendar(data);

        this.getVouchers().subscribe((response) => {
          this.resetDataList();
          Object.keys(this.calendareventsMap).forEach((key) => {
            this.updateDataList(key, this.calendareventsMap[key]);
          });
          this.dataList.push({ key: "BUONI PASTO", value: response.total });
        });

        Object.keys(data).forEach((key: any) => {
          data[key].forEach((calendar: CalendarEvent) => {
            if (calendar.calendarDay === this.todayDate) {
              this.todayCalendarevent = calendar;
            }
            if (calendar.calendarDay === this.tomorrowDate) {
              this.tomorrowCalendarevent = calendar;
            }
          });
        });
      });

    return this.calendareventsMap;
  }

  onCalendarButtonClick(event: any): void {
    const monthYear = event.capitalizedText;

    const [month, year] = monthYear.split(' ');

    const monthMap: { [key: string]: string } = {
      'Gennaio': '01',
      'Febbraio': '02',
      'Marzo': '03',
      'Aprile': '04',
      'Maggio': '05',
      'Giugno': '06',
      'Luglio': '07',
      'Agosto': '08',
      'Settembre': '09',
      'Ottobre': '10',
      'Novembre': '11',
      'Dicembre': '12'
    };
  
    const monthNumber = monthMap[month];
  
    this.selectedDate = `${year}-${monthNumber}`;
  
    console.log("Selected date set to:", this.selectedDate);

    this.getCalendarevents();
  }

  isTodayInCalendar: boolean = false;

  isCurrentMonth(date: string): boolean {
    const dateObj = new Date(date);
    const today = new Date();
    return dateObj.getMonth() === today.getMonth() && dateObj.getFullYear() === today.getFullYear();
  }

  valorizeCalendar(data: CalendarEvents) {
    const events: EventInput[] = [];
    const today = formatDate(new Date(), "yyyy-MM-dd", "it");
    this.isTodayInCalendar = false;

    Object.keys(data).forEach((element) => {
      data[element].forEach((day) => {
        if (day.calendarDay === today && this.isCurrentMonth(this.selectedDate)) {
          this.isTodayInCalendar = true;
        }

        const event: EventInput = {
          id: day.id.toString(),
          title: day.calendarStatusType,
          start: day.calendarDay,
          end: day.calendarDay,
          allDay: true,
          backgroundColor:
            this.colors[toLower(day.calendarStatusType)] || "#000000",
          borderColor:
            this.colors[toLower(day.calendarStatusType)] || "#000000",
        };
        events.push(event);
      });
    });

    if (!this.isTodayInCalendar && this.isCurrentMonth(this.selectedDate)) {
      const indecisoColor = "#8a8a8a";

      const event: EventInput = {
        id: "indeciso-today",
        title: "INDECISO",
        start: today,
        end: today,
        allDay: true,
        backgroundColor: indecisoColor,
        borderColor: indecisoColor,
      };
      events.push(event);
    }

    return this.events = events;

  }

  updateDataList(key: string, value: any): void {
    if (key !== "PERMESSO") {
      this.dataList.push({ key: key, value: value.length });
    }
  }

  getVouchers(): Observable<any> {
    return this.roleService.getVouchers(
      this.selectedDate,
      this.selectedUser.id
    );
  }

  resetDataList() {
    this.dataList = [];
  }

  //! CALENDARIO
  events: EventSourceInput = [];
  startDate: Date;
  endDate: Date;

  handleCalendarMonth(event): void {
    this.resetDataList();
    const formattedDate = formatDate(event, "yyyy-MM", "it");

    this.roleService
      .getCalendarData(formattedDate, this.selectedUser.id)
      .subscribe((data: any) => {
        const events: EventInput[] = [];
        Object.keys(data).forEach((element) => {
          data[element].forEach((day) => {
            const event: EventInput = {
              id: day.id,
              title: day.calendarStatusType,
              start: day.calendarDay,
              end: day.calendarDay,
              allDay: true,
              backgroundColor: this.colors[toLower(day.calendarStatusType)],
              borderColor: this.colors[toLower(day.calendarStatusType)],
            };
            events.push(event);
          });
        });
        this.events = events;
      });
  }

  onYearSelected($event) {
    this.selectedDate = $event;
    this.startDate = $event;
    this.endDate = $event;
    this.getCalendarevents();
  }

  onMonthSelected(event) {
    this.startDate = event.start_date;
    this.endDate = event.end_date;
    this.selectedDate = event.start_date;
    this.getCalendarevents();
  }

  isDateValid: boolean = true;
  checkDate() {
    !isNullOrUndefined(this.startDate) || !isNullOrUndefined(this.endDate)
      ? (this.isDateValid =
          this.startDate.toString() != "Invalid Date" &&
          this.endDate.toString() != "Invalid Date")
      : (this.isDateValid = true);
  }

  isMonthPickerVisible: boolean = false;
  showMonthPicker() {
    this.isMonthPickerVisible = true;
  }

  changeMonthPickerVisibility(event) {
    this.isMonthPickerVisible = !this.isMonthPickerVisible;
  }

  //! MODALS
  modalRef: BsModalRef;

  // MODAL DI CREAZIONE DI UN NUOVO CALENDAREVENT
  openModalProva(date: string, calendarEvent?: CalendarEvent) {
    this.modalRef = this.modalService.show(CreateCalendareventModalComponent, {
      initialState: {
        date: date,
        userId: this.selectedUser.id,
        calendarEvent: calendarEvent, // Passa calendarEvent se stai modificando un evento esistente
      },
    });

    const modalComponent = this.modalRef
      .content as CreateCalendareventModalComponent;
    modalComponent.onClose.subscribe(() => {
      this.getCalendarevents();
      this.handleCalendarMonth(this.selectedDate);
    });
  }

  closeModalProva() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }
}
